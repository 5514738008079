import { entries } from '../types/entries'
import axiosInstance from './axiosInstance'

export const createVisitor = async (
  params: FormData,
  isEmergency: boolean,
  token?: string
): Promise<entries> => {
  params.append('isEmergency', isEmergency.toString())

  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        isEmergency
      }
    : {}
  try {
    const response = await axiosInstance.post(`/api/visitor`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        ...headers
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en createVisitor:', error)
    throw error
  }
}
