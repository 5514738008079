import moment from 'moment'
import { DashCheckPoint } from '../../../types/dashboard'
const capitalice = (text: string) =>
  text.toLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()))

export const barChartOptionsCheckPoint = (DashCheckPoint: DashCheckPoint) => ({
  title: {
    text: 'Limpieza / Mantenimiento',
    subtext: `Mes en curso $ ${capitalice(moment().locale('es').format('MMMM'))}`,
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },

  xAxis: {
    type: 'category',
    data: [
      'Total de acciones',
      'Acciones limpieza',
      'Limpiezas completadas',
      'Acciones mantenimiento',
      'Mantenimiento completados',
      'Acciones seguridad',
      'Seguridad completadas'
    ],
    axisLabel: {
      rotate: 15, // Rotar las etiquetas 90 grados
      color: '#fff',
      fontSize: 12
    }
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#333' // Cambia el color de las líneas de división si lo necesitas
      }
    }
  },
  series: [
    {
      name: 'Acciones',
      type: 'bar',
      data: [
        { value: DashCheckPoint.T_actions, itemStyle: { color: '#3b82f6' } },
        //limpieza
        {
          value: DashCheckPoint.T_action_Clear,
          itemStyle: { color: '#60a5fa' }
        },
        {
          value: DashCheckPoint.ok_action_Clear,
          itemStyle: { color: '#10b981' }
        },
        //mantiniemiento
        {
          value: DashCheckPoint.T_action_maintenance,
          itemStyle: { color: '#93c5fd' }
        },
        {
          value: DashCheckPoint.ok_action_maintenance,
          itemStyle: { color: '#34d399' }
        },
        //Seguridad
        {
          value: DashCheckPoint.T_action_Segurity,
          itemStyle: { color: '#93c5fd' }
        },
        {
          value: DashCheckPoint.ok_action_Segurity,
          itemStyle: { color: '#93c5fd' }
        }
      ]
    }
  ]
})

export const barHorizontalCheckPoint = (DashCheckPoint: DashCheckPoint) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: DashCheckPoint.staffs.map(staff => `${staff.name} ${staff.Type ? staff.Type : ''}`),
    type: 'scroll', // Permite la navegación si hay demasiados nombres
    textStyle: {
      fontSize: 14 // Ajusta el tamaño de la fuente para que quepan más nombres
    }
  },
  yAxis: {
    type: 'category',
    data: DashCheckPoint.staffs.map(
      staff => `${staff.name} \n ${staff.Type ? staff.Type : ''}`
    ),
    axisLabel: {
      interval: 0, // Muestra todas las etiquetas

      fontSize: 12 // Ajusta el tamaño de la fuente para que quepan mejor
    }
  },
  xAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        color: '#fcfbff77' // Cambia el color de las líneas verticales aquí
      }
    }
  },
  grid: {
    left: '20%', // Deja espacio suficiente para las etiquetas
    right: '10%',
    bottom: '10%',
    top: '10%'
  },
  series: [
    {
      name: 'Staff S_TT',
      type: 'bar',
      data: DashCheckPoint.staffs.map(staff => staff.S_TR),
      label: {
        color: '#ffffff', // Cambiar color al hacer hover
        show: true,
        position: 'right' // Etiquetas al final de la barra
      },
      emphasis: {
        focus: 'series',
        itemStyle: {
          color: '#ff6347' // Cambiar color al hacer hover
        }
      }
    }
  ]
})
