import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const axiosInstanceWAPP = axios.create({
  baseURL: process.env.REACT_APP_API_URL_WHATS,
  timeout: 1000 * 60 * 5,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstanceWAPP.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = Cookies.get('token')

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      } as AxiosRequestHeaders
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstanceWAPP.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },

  error => {
    return Promise.reject(error)
  }
)

export default axiosInstanceWAPP
