import { ConfigProvider, Input } from 'antd'
import React, { useEffect } from 'react'
import { BorderIpass } from '../../../BorderIpass/Index'

type AntdTextAreaProps = React.ComponentProps<typeof Input.TextArea>

interface MyTextAreaProps extends Omit<AntdTextAreaProps, 'onResize'> {
  borderColor?: boolean
  labelError?: string
  error?: boolean
  BorderSize?: number
  colorBg?: string
  personClassName?: string
  onChange: (event: any) => void
}

export const MyTextArea: React.FC<MyTextAreaProps> = ({
  borderColor,
  labelError,
  error,
  BorderSize = 2,
  disabled,
  onChange,
  colorBg = 'rgba(56, 78, 183, 0.3)',
  personClassName,
  ...textareaProps
}) => {
  useEffect(() => {}, [labelError, error])

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colorBg,
            colorBorder: '#00000000',
            colorPrimary: '#00000000',
            hoverBorderColor: '#00000000',
            colorText: 'white',
            colorTextBase: 'white',
            colorIcon: '#00000000',
            colorIconHover: '#00000000',
            borderRadius: 20,
            activeBorderColor: '#00000000',
            colorBgTextHover: '#00000000',
            colorBorderBg: '#00000000',
            colorPrimaryBorderHover: '#00000000',
            colorBorderSecondary: '#00000000',
            colorInfoBorderHover: '#00000000',
            colorPrimaryBorder: '#00000000',
            colorSuccessBorderHover: '#00000000',
            colorErrorBorderHover: '#00000000',
            colorWarningBorderHover: '#00000000',
            colorInfoBorder: '#00000000',
            colorErrorBorder: '#00000000',
            colorSuccessBorder: '#00000000',
            colorWarningBorder: '#00000000',
            colorTextDisabled: 'white'
          }
        },
        token: {
          colorBgBase: colorBg,
          colorBgContainer: colorBg,
          colorText: 'white',
          colorTextPlaceholder: 'white',
          colorIcon: '#00000000',
          colorIconHover: '#00000000',
          colorBorderBg: '#00000000',
          colorPrimaryBorderHover: '#00000000',
          colorBorderSecondary: '#00000000',
          colorInfoBorderHover: '#00000000',
          colorPrimaryActive: '#00000000',
          colorBorder: '#00000000',
          colorBgSolidHover: '#00000000',
          controlOutlineWidth: 0,
          colorSuccessBorderHover: '#00000000',
          colorErrorBorderHover: '#00000000',
          colorWarningBorderHover: '#00000000'
        }
      }}
    >
      <BorderIpass
        personClassName={personClassName}
        margin={BorderSize}
        status={
          borderColor ? (error ? 'Error' : disabled ? 'Disable' : 'Normal') : 'NO_BORDER'
        }
      >
        <Input.TextArea
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange(e.target.value)
          }}
          {...textareaProps} // Propagamos las propiedades válidas
        />
        {labelError && error && (
          <p style={{ color: 'red', margin: '0px', fontSize: '14px', marginTop: '-15px' }}>
            {labelError}
          </p>
        )}
      </BorderIpass>
    </ConfigProvider>
  )
}
