import axiosInstanceWAPP from './axiosInstanceWAPP'

export const get_whatsapp = async (
  clientId: string,
  token: string
): Promise<{ status: string }> => {
  try {
    const response = await axiosInstanceWAPP.get(`/get_whatsapp/${clientId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export const reset_whatsapp = async (
  clientId: string,
  token: string
): Promise<{ status: string }> => {
  try {
    const response = await axiosInstanceWAPP.get(`/reset/${clientId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en reset_whatsapp:', error)
    throw error
  }
}

export const send_ticket = async (
  clientId: string,
  oldStaffId: string,
  newStaff: string,
  ticket: string,
  token: string,
  notify?: boolean
): Promise<{ status: string }> => {
  try {
    const response = await axiosInstanceWAPP.post(
      `/send_ticket`,
      { oldStaffId, newStaff, clientId, ticket, notify },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error send_ticket : ', error)
    throw error
  }
}

export const ticket_status = async (
  params: FormData,
  token: string
): Promise<{ status: string }> => {
  try {
    const response = await axiosInstanceWAPP.put(`/api/tickets`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error ticket_status:', error)
    throw error
  }
}

export const sendDiscredit = async (id: string, token: string): Promise<boolean> => {
  try {
    const response = await axiosInstanceWAPP.post(`/sendDiscredit/${id}`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en desacreditar al trabajador del plan', error)
    throw error
  }
}
