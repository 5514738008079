import React, { FC, useEffect, useState } from 'react'
import { useAuth } from '../../Provider/AuthProvider'
import { listGraphPayment } from '../../types/Payments'
import ReactECharts from 'echarts-for-react'

import { getDasboardGraphPayments } from '../../services/Payments'
import { useLoading } from '../../Provider/LoadingProvider'

import moment from 'moment'

const graphResident = {
  total: 0,
  enVerificacion: 0,
  mora: 0,
  alDia: 0
}

const graphClient = {
  clientId: '',
  name: '',
  month: moment(Date.now()).format('MM YYYY'),
  balance_month: 0,
  type_Pago: 0,
  type_Cargos: 0,
  type_Mora: 0,
  type_Reserva: 0,
  type_Cuota: 0
}

export const ChartsAcreditamiento: FC<{ id: string }> = ({ id }) => {
  const { SocketWhats } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [GeneralData, setGeneralData] = useState<listGraphPayment>({
    graphResident,
    graphClient
  })

  useEffect(() => {
    const eventName = `payment-${id}`

    const eventAction = (message: string) => {
      getGeneralData(message)
    }

    if (SocketWhats) {
      SocketWhats.on(eventName, eventAction)
    }

    if (id) {
      getGeneralData(id)
    }

    return () => {
      SocketWhats && SocketWhats.off(eventName, eventAction)
    }
  }, [id])

  const getGeneralData = async (id: string) => {
    try {
      startLoading()
      const data = await getDasboardGraphPayments(id)
      setGeneralData(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const barChartOptions = () => {
    const { month, type_Pago, type_Cargos, type_Mora, type_Reserva, type_Cuota } =
      GeneralData.graphClient

    const categories = ['TOTAL', 'CUOTA', 'CARGOS', 'RESERVA', 'MORA', 'PAGADO']
    const data = [
      {
        name: 'TOTAL',
        value: type_Cargos + type_Mora + type_Reserva + type_Cuota,
        color: '#a239ca' // Morado vibrante
      },
      { name: 'CUOTA', value: type_Cuota, color: '#3f51b5' }, // Azul oscuro
      { name: 'CARGOS', value: type_Cargos, color: '#673ab7' }, // Azul morado
      { name: 'RESERVA', value: type_Reserva, color: '#8e24aa' }, // Fucsia suave
      { name: 'MORA', value: type_Mora, color: '#d81b60' }, // Fucsia fuerte
      { name: 'PAGADO', value: type_Pago, color: '#ff4081' } // Rosa vibrante
    ]

    return {
      title: {
        text: `Mes en curso: ${month}`,
        subtext: 'Distribución de Cobros',
        left: 'center',
        textStyle: {
          color: '#fff'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          color: '#fff'
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#fff'
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)'
          }
        }
      },
      series: [
        {
          name: 'Cobros',
          type: 'bar',
          data: data.map(item => ({
            value: item.value,
            itemStyle: {
              color: item.color
            }
          })),
          label: {
            show: true,
            position: 'top',
            color: '#fff'
          }
        }
      ],
      legend: {
        data: data.map(item => item.name), // Agregar todas las categorías a la leyenda
        bottom: 0,
        textStyle: {
          color: '#fff'
        },
        itemGap: 15 // Separación entre elementos de la leyenda
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      backgroundColor: '#14113f' // Fondo azul oscuro
    }
  }

  return <ReactECharts option={barChartOptions()} style={{ height: '400px', width: '100%' }} />
}
