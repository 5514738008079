import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import './styles.scss'
import { Task } from './index'
import { PreViewQr } from './PreViewQr'

export const SortableItem: React.FC<{ task: Task }> = ({ task }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable(
    {
      id: task.id
    }
  )

  const style = {
    transform: transform ? `translateY(${transform.y}px)` : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: isDragging ? 'grabbing' : 'grab'
  }
  return (
    <div
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="itemDragAndDrop"
    >
      <div className="title">
        <p>{task.title}</p>
      </div>
      <div className="AddressAndQr">
        <p>{task.address}</p>
        <PreViewQr IDLocation={task.id} />
      </div>
    </div>
  )
}
