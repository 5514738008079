import React, { useState, useRef } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom' // Para manejar el enlace al PDF
import Modal from '../GeneralComponents/Modal'

const ZoomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    padding: 0,
    maxWidth: 'none',
    boxShadow: theme.shadows[1]
  }
}))

const ZoomImage = ({
  src,
  alt,
  className,
  disableZoom
}: {
  src: string
  alt: string
  className?: string
  disableZoom?: boolean
}) => {
  const [scale, setScale] = useState(1)
  const [translate, setTranslate] = useState({ x: 0, y: 0 })
  const imgRef = useRef<HTMLImageElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleWheel = (event: React.WheelEvent) => {
    setScale(prevScale =>
      event.deltaY < 0 ? Math.min(prevScale + 0.1, 3) : Math.max(prevScale - 0.1, 1)
    )
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    if (containerRef.current && imgRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect()
      const offsetX = (event.clientX - left) / width
      const offsetY = (event.clientY - top) / height
      setTranslate({
        x: (offsetX - 0.5) * (scale - 1) * 100,
        y: (offsetY - 0.5) * (scale - 1) * 100
      })
    }
  }

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40vw',
        height: '40vh',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '8px',
        zIndex: 1300
      }}
      onWheel={!disableZoom ? handleWheel : undefined}
      onMouseMove={!disableZoom ? handleMouseMove : undefined}
    >
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        style={{
          transform: `scale(${scale}) translate(${translate.x}%, ${translate.y}%)`,
          transition: 'transform 0.2s',
          maxHeight: '100%',
          maxWidth: '100%',
          cursor: scale > 1 ? 'move' : 'default'
        }}
      />
    </div>
  )
}

const PhotoTooltip = ({
  photos,
  altText,
  className,
  selectPlacement,
  altPhoto
}: {
  photos: string[]
  altText: string
  className?: string
  altPhoto: string
  selectPlacement?: TooltipProps['placement']
}) => {
  const [modalPdf, setModalPdf] = useState(false)
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  const fileIconDictionary: { [extension: string]: string } = {
    '.pdf': '/icons/pdf-icon.svg',
    '.docx': '/icons/word-icon.svg',
    '.xlsx': '/icons/excel-icon.svg',
    '.mp3': '/icons/audio-icon.svg',
    unknown: '/icons/unknown-icon.svg'
  }

  const validPhotos = photos.filter(photo => photo && photo !== 'undefined' && photo !== '')
  const documentExtensions = Object.keys(fileIconDictionary)

  const getFileExtension = (url: string) => {
    const lastDotIndex = url.lastIndexOf('.')
    return lastDotIndex !== -1 ? url.substring(lastDotIndex) : ''
  }

  const fileExtension = validPhotos[0] ? getFileExtension(validPhotos[0]) : ''
  const isDocument = documentExtensions.includes(fileExtension)

  const handleDownload = () => {
    if (isDocument && validPhotos[0]) {
      const link = document.createElement('a')
      link.href = validPhotos[0]
      link.download = `file${fileExtension}`
      link.click()
    }
  }

  const handleOpenPdf = () => {
    if (fileExtension === '.pdf' && validPhotos[0]) {
      setPdfUrl(validPhotos[0])
      setModalPdf(true)
    }
  }

  const handleModalClose = () => {
    setModalPdf(false)
    setPdfUrl(null)
  }

  return (
    <>
      <ZoomTooltip
        title={
          !isDocument && validPhotos.length > 0 ? (
            <ZoomImage
              src={validPhotos[0]}
              alt={altText}
              className={className}
              disableZoom={false}
            />
          ) : (
            ''
          )
        }
        arrow={!isDocument}
        placement={selectPlacement || 'top'}
      >
        <img
          onClick={
            isDocument
              ? fileExtension === '.pdf'
                ? handleOpenPdf
                : handleDownload
              : undefined
          }
          className={className}
          src={
            isDocument
              ? fileIconDictionary[fileExtension] || fileIconDictionary.unknown
              : validPhotos[0] || altPhoto
          }
          alt={altText}
          style={{
            width: '30px',
            height: '30px',
            objectFit: 'cover',
            borderRadius: '50%',
            cursor: isDocument ? 'pointer' : 'default'
          }}
        />
      </ZoomTooltip>

      {modalPdf && pdfUrl && (
        <Modal
          isOpen={modalPdf}
          styleContainer={{
            width: '100%',
            height: '100%',
            padding: '0px',
            background: 'rgb(0 0 0 / 0%)',
            overflow: 'hidden'
          }}
          styleContainerContent={{ padding: '0 60px', height: '100%', maxHeight: '100vh' }}
          onClose={handleModalClose}
        >
          <object data={pdfUrl} type="application/pdf" width="100%" height="99%">
            <Link to={pdfUrl} target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </Link>
          </object>
        </Modal>
      )}
    </>
  )
}

export default PhotoTooltip
