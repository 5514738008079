import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import XLSX from 'xlsx-js-style'
import './style.scss'
import { useAuth } from '../../../Provider/AuthProvider'
import { io } from 'socket.io-client'
import { DashCheckPoint, DashCheckReport } from '../../../types/dashboard'
import ReactECharts from 'echarts-for-react'
import Modal from '../../../components/GeneralComponents/Modal'
import ReportModal from '../../../components/modalDashboard/seeMoreCheckPoint'
import Button from '../../../components/Form/Button'

import { WorkHistory, ExpandLess, ExpandMore, GridOn } from '@mui/icons-material/'
import { IconButton, Fade } from '@mui/material'
import {
  getdashCheckReportFilter,
  getGeneralCheckpointByClientId,
  getLogsCheckPoint
} from '../../../services/dashboard'
import { sendDiscredit } from '../../../services/whatsapp'

import { useLoading } from '../../../Provider/LoadingProvider'
import { LightTooltip } from '../../../components/tooltips/text'
import PhotoTooltip from '../../../components/tooltips/img'
import Icon from '@ant-design/icons'
import { ReactComponent as checkPoint1 } from '../../../assets/svg/checkPoint1.svg'
import { ReactComponent as checkPoint2 } from '../../../assets/svg/checkPoint2.svg'

import {
  faWrench,
  faCity,
  faHandSparkles,
  faCircle,
  faCircleNotch,
  faFlagCheckered,
  faUserSlash,
  faCircleArrowUp,
  faCircleArrowDown,
  faShieldHalved
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientBanner from '../../../components/GeneralComponents/SliderBanner'
import DateRangePicker from '../../../components/Form/RangePicker3'
import { useDebounce } from '../../../hooks/useDebounce'
import { MultiSelectANTD } from '../../../components/Form/multiSelet'
import moment from 'moment'
import { barChartOptionsCheckPoint, barHorizontalCheckPoint } from './Chars'
import ExportToExcel from './generateXML'
interface typesearch {
  dateSelect?: string[]
  staffs?: string[]
  routinesType?: string[]
  status?: string[]
}
const Dashboard = () => {
  const { user, token } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const [DashCheckPoint, setDashCheckPoint] = useState<DashCheckPoint>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [idSearch, setIdSearch] = useState<string | undefined>(undefined)
  const [slider, setSlider] = useState(true)
  const [selected, setSelected] = useState<{ id: string; action: string } | undefined>(
    undefined
  )

  const [search, setSearch] = useState<typesearch>()

  const debounceValue = useDebounce(search, 1000)

  useEffect(() => {
    if (!idSearch || !DashCheckPoint || !debounceValue) return

    getGeneralDataForFilter(idSearch, debounceValue)
  }, [debounceValue])

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)

    dataId && getGeneralData(dataId)
  }, [id, idSearch, setIdSearch, user])

  useEffect(() => {
    const socket = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )
    if (idSearch) {
      socket.on(`CheckPoint-${idSearch}`, (id: string) => {
        getGeneralData(id)
      })
    }
    return () => {
      socket.disconnect()
    }
  }, [idSearch, search])

  const getGeneralData = async (id: string) => {
    const hasFilters =
      (search?.dateSelect?.length || 0) > 0 ||
      (search?.staffs?.length || 0) > 0 ||
      (search?.routinesType?.length || 0) > 0 ||
      (search?.status?.length || 0) > 0

    try {
      if (hasFilters) return
      startLoading()
      const data = await getGeneralCheckpointByClientId(id)
      setDashCheckPoint(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const getGeneralDataForFilter = async (id: string, dataSearch: any) => {
    try {
      startLoading()
      const filteredSearch = Object.fromEntries(
        Object.entries(dataSearch || {}).filter(
          ([, value]) => value && !(Array.isArray(value) && value.length === 0) && value !== ''
        )
      )
      const newDashCheckReport: DashCheckReport[] = await getdashCheckReportFilter(
        id,
        filteredSearch
      )
      setDashCheckPoint(lastData =>
        lastData
          ? {
              ...lastData,
              dashCheckReport: newDashCheckReport
            }
          : undefined
      )
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    setSearch(prevSearch => ({
      ...prevSearch,
      [name]: value
    }))
  }

  const StatusFilter = [
    { value: 'inProgress', label: 'En Proceso' },
    { value: 'finalized', label: 'Finalizado' },
    { value: 'notTaken', label: 'No Tomado' }
  ]

  if (!DashCheckPoint)
    return (
      <div className="DashboardContainer">
        <div className="title_Dashboard">
          <h4>Dashboard</h4>
        </div>
      </div>
    )

  const handleView = (id: string, action: string) => {
    setSelected({ id, action })
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelected(undefined)
    if (idSearch) {
      getGeneralData(idSearch)
    }
  }

  const Actions: React.FC<{
    id: string
    state: string
  }> = ({ id, state }) => {
    const isDisabled = state === 'notTaken'
    const inProcess = state === 'inProgress'

    const disabledButtonStyles = {
      color: 'gray',
      pointerEvents: 'none'
    }

    const enabledButtonStyles = {
      color: 'white'
    }

    return (
      <>
        <LightTooltip title="Historial" TransitionComponent={Fade}>
          <div>
            <IconButton
              onClick={() => handleView(id, 'record')}
              disabled={isDisabled}
              style={isDisabled ? disabledButtonStyles : enabledButtonStyles}
            >
              <WorkHistory />
            </IconButton>
          </div>
        </LightTooltip>

        <LightTooltip title="Desacreditar Trabajador" TransitionComponent={Fade}>
          <div>
            <IconButton
              onClick={() => handleView(id, 'discredit')}
              disabled={!inProcess}
              style={!inProcess ? disabledButtonStyles : enabledButtonStyles}
            >
              <FontAwesomeIcon icon={faUserSlash} />
            </IconButton>
          </div>
        </LightTooltip>

        <LightTooltip title="Descargar Reporte" TransitionComponent={Fade}>
          <IconButton
            onClick={() => handleExport(id)}
            disabled={isDisabled}
            style={isDisabled ? disabledButtonStyles : enabledButtonStyles}
          >
            <GridOn />
          </IconButton>
        </LightTooltip>
      </>
    )
  }

  const handleExport = async (id: string) => {
    try {
      startLoading()

      // Obtener el detalle del reporte
      const detailCheckReport = await getLogsCheckPoint(id)

      // Obtener datos de encabezado
      const dataHeader = DashCheckPoint.dashCheckReport.find(report => report.id === id)!

      // Crear datos de la tabla
      const data = detailCheckReport.logs
        ? detailCheckReport.logs.map(report => {
            return {
              LUGAR: report.address,
              ACCION: report.name,
              'HORA CIERRE': report.hour,
              DURACIÓN: report.record,
              OBJETIVO: report.target || '',
              NOVEDAD: report.targetNote || '',
              SOLUCIÓN: report.targetSolutionNote || ''
            }
          })
        : []

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet([]) // Crear hoja vacía para llenarla manualmente

      // Combinar celdas A1 y B1 para el título
      worksheet['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, // A1:C1
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // A2:C2
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } }, // A3:C3
        { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } }, // A4:C4
        { s: { r: 4, c: 0 }, e: { r: 4, c: 1 } }, // A5:C5
        { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } }, // A6:C6
        { s: { r: 6, c: 0 }, e: { r: 6, c: 1 } }, // A7:C7
        { s: { r: 7, c: 0 }, e: { r: 7, c: 1 } } // A8:C8
      ]

      // Colocar el título en la celda combinada A1:C1
      worksheet['A1'] = {
        v: `PLAN: ${dataHeader.namePlan}`,
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: 'center', vertical: 'center' }
        }
      }

      // Colocar información adicional en las filas siguientes
      worksheet['A2'] = {
        v: `FECHA:`,
        s: { font: { bold: true } }
      }

      worksheet['C2'] = {
        v: `${dataHeader.lastUpdateTime}`
      }

      worksheet['A3'] = {
        v: `TRABAJADOR:`,
        s: { font: { bold: true } }
      }

      worksheet['C3'] = {
        v: `${dataHeader.nameStaff}`
      }

      worksheet['A4'] = { v: `ROL:`, s: { font: { bold: true } } }
      worksheet['C4'] = { v: `${dataHeader.typeStaff}` }

      worksheet['A5'] = {
        v: `BALANCE:`,
        s: { font: { bold: true } }
      }

      worksheet['C5'] = {
        v: `${dataHeader.T_actions || 0} / ${dataHeader.R_actions || 0}  /${
          dataHeader.P_action || 0
        }%`
      }

      worksheet['A6'] = {
        v: `TIEMPO:`,
        s: { font: { bold: true } }
      }

      worksheet['C6'] = {
        v: `${dataHeader.recordReport}`
      }

      worksheet['A7'] = {
        v: `+ RAPIDA:`,
        s: { font: { bold: true } }
      }

      worksheet['C7'] = {
        v: `${detailCheckReport.faster}`
      }

      worksheet['A8'] = {
        v: `+ LENTA:`,
        s: { font: { bold: true } }
      }

      worksheet['C8'] = {
        v: `${detailCheckReport.slower}`
      }

      worksheet['A9'] = {
        v: `TIEMPO FUERA`,
        s: { font: { bold: true } }
      }

      worksheet['C9'] = {
        v: `${dataHeader.C_TOut || 0} / ${dataHeader.C_OutMS || 0}`
      }

      // Ajustar rango para que la primera fila de datos esté después del encabezado
      const startingRow = 11

      // Agregar los datos de la tabla comenzando en la fila startingRow
      XLSX.utils.sheet_add_json(worksheet, data, { origin: `A${startingRow}` })

      // Estilo de encabezado de columnas
      const headerCellStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '800080' } },
        alignment: { horizontal: 'center', vertical: 'center' }
      }

      // Ajustar el ancho de la columna A (150px) y otras columnas
      worksheet['!cols'] = [
        { wpx: 150 }, // Columna A con ancho 150px
        { wpx: 150 }, // Columna A con ancho 150px
        { wpx: 100 }, // Columna B con ancho 100px
        { wpx: 100 } // Columna C con ancho 200px
      ]

      // Aplicar estilos a los encabezados de la tabla en startingRow
      const range = XLSX.utils.decode_range(worksheet['!ref']!)

      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + startingRow
        if (worksheet[address]) {
          worksheet[address].s = headerCellStyle
        }
      }

      // Aplicar estilos a las celdas de datos
      const cellStyle = {
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } }
        }
      }

      for (let R = startingRow; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: R, c: C })
          if (worksheet[address]) {
            worksheet[address].s = {
              ...cellStyle,
              fill: { fgColor: { rgb: R % 2 === 0 ? 'FFFFFF' : 'E6E6FA' } } // Intercalar colores
            }
          }
        }
      }

      // Añadir hoja y descargar
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Reportes')
      XLSX.writeFile(
        workbook,
        `${user?.name}-${moment(Date.now()).format('DD_MM_YYYY-h_mm')}.xlsx`
      )
    } catch (error) {
      console.error('Error al exportar:', error)
    } finally {
      stopLoading()
    }
  }

  const status: {
    [key: string]: string
  } = {
    finalized: 'Finalizado',
    inProgress: 'En Proceso',
    notTaken: 'No Tomado'
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    finalized: <FontAwesomeIcon icon={faFlagCheckered} className="dashBoardClose" />,
    inProgress: <FontAwesomeIcon icon={faCircleNotch} className="dashBoardOpen" />,
    notTaken: <FontAwesomeIcon icon={faCircle} className="dashBoardHold" />
  }
  const handleSendDiscredit = async () => {
    if (selected && idSearch) {
      await sendDiscredit(selected.id, token)
      closeModal()
    }
  }

  const Waring: React.FC = () => {
    return (
      <>
        <div className="WaringModal">
          <div className="Waring-header">
            <h2>ADVERTENCIA</h2>
          </div>
          <div className="Waring-text">
            Estas a punto de desincorporar al trabajador de este plan
            <br /> <br />
            <span>¿Estas seguro de hacer este cambio?</span>
          </div>

          <div className="containerButtons">
            <Button text="SI" styleType={`normal`} onClick={handleSendDiscredit} />

            <Button
              text="NO"
              styleType={`normal`}
              onClick={() => {
                setSelected(undefined)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="DashboardContainer">
      <div className="title_Dashboard">
        <h4>
          Dashboard CheckPoint {user?.role !== 'corporate' ? `` : DashCheckPoint.clientName}
        </h4>
      </div>
      {DashCheckPoint && (
        <div>
          {selected && (
            <>
              {selected.action === 'record' && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                  <ReportModal
                    PreReport={
                      DashCheckPoint.dashCheckReport.find(report => report.id === selected.id)!
                    }
                    ClientName={DashCheckPoint.clientName!}
                  />
                </Modal>
              )}

              {selected.action === 'discredit' && (
                <Modal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  styleContainer={{ width: '480px', height: '270px' }}
                >
                  <Waring />
                </Modal>
              )}
            </>
          )}

          <div className="infoGeneralContainer">
            <div style={{ position: 'absolute', left: '-40px' }}>
              <IconButton onClick={() => setSlider(!slider)}>
                {slider ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>

            <LightTooltip title="Acciones totales" followCursor TransitionComponent={Fade}>
              <li className="generalInf tooltip">
                <FontAwesomeIcon icon={faCity} className="icon" />
                <span>{DashCheckPoint?.P_activity}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Acciones de limpieza  "
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faHandSparkles} className="icon" />
                <span>{DashCheckPoint.P_clear}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Acciones de Mantenimiento"
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faWrench} className="icon" />
                <span>{DashCheckPoint.P_maintenance}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Acciones de Seguridad"
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faShieldHalved} className="icon" />
                <span>{DashCheckPoint.P_Segurity}%</span>
              </li>
            </LightTooltip>

            <li className="generalInf">
              <LightTooltip
                title="Cantidad tiempos fuera"
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf IconSvgCheck">
                  <Icon component={checkPoint2} className="icon" />
                  <span>{DashCheckPoint.P_TOut}</span>
                </span>
              </LightTooltip>

              <LightTooltip
                title="Relación mes anterior"
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf">
                  <FontAwesomeIcon
                    icon={DashCheckPoint.P_lastTOut > 0 ? faCircleArrowUp : faCircleArrowDown}
                    className={`icon ${
                      DashCheckPoint.P_lastTOut > 0 ? 'icon-negative' : 'icon-positive'
                    }`}
                  />
                  <span>
                    {DashCheckPoint.P_lastTOut > 0
                      ? DashCheckPoint.P_lastTOut
                      : DashCheckPoint.P_lastTOut * -1}
                    %
                  </span>
                </span>
              </LightTooltip>
            </li>

            <li className="generalInf">
              <LightTooltip
                title="Tiempo fuera acumulado"
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf IconSvgCheck">
                  <Icon component={checkPoint1} className="icon" />
                  <span>{DashCheckPoint.P_TOutMS}</span>
                </span>
              </LightTooltip>

              <LightTooltip
                title="Relación mes anterior"
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf">
                  <FontAwesomeIcon
                    icon={
                      DashCheckPoint.P_lastTOutMS > 0 ? faCircleArrowUp : faCircleArrowDown
                    }
                    className={`icon ${
                      DashCheckPoint.P_lastTOutMS > 0 ? 'icon-negative' : 'icon-positive'
                    }`}
                  />
                  <span>
                    {DashCheckPoint.P_lastTOutMS > 0
                      ? DashCheckPoint.P_lastTOutMS
                      : DashCheckPoint.P_lastTOutMS * -1}
                    %
                  </span>
                </span>
              </LightTooltip>
            </li>
          </div>

          <ClientBanner
            items={DashCheckPoint.staffs}
            renderItem={(client, id) => (
              <>
                <LightTooltip title={`${client.name || ''}`} followCursor>
                  <img
                    className="client-photo"
                    src={
                      client.photo !== 'null' && client.photo !== 'undefined'
                        ? client.photo
                        : '/images/icons/user.png'
                    }
                    alt={client.name || 'User'}
                  />
                </LightTooltip>

                <LightTooltip title="Acciones tomadas" followCursor>
                  <p className="client-info">{client.S_TT || 0}/</p>
                </LightTooltip>

                <LightTooltip title="Acciones resueltas" followCursor>
                  <p className="client-info">{client.S_TR || 0}/</p>
                </LightTooltip>

                <LightTooltip title="Eficiencia" followCursor>
                  <p className="client-info">{client.S_OK || 0}%</p>
                </LightTooltip>

                <LightTooltip title="Tiempos fuera / acumulados" followCursor>
                  <p className="client-info">
                    {`/${client.S_TOut || 0}/${client.S_TOutMS || 0}`}
                  </p>
                </LightTooltip>
              </>
            )}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: slider ? `2.5rem` : '0rem',
              maxHeight: slider ? '400px' : '0px',
              overflow: 'hidden',
              transition: 'max-height 0.5s linear 0s, margin-top 0.5s linear 0s'
            }}
          >
            <div style={{ width: '45%' }}>
              <ReactECharts
                option={barChartOptionsCheckPoint(DashCheckPoint)}
                style={{ height: '400px', width: '100%' }}
              />
            </div>
            <div style={{ width: '55%' }}>
              <ReactECharts
                option={barHorizontalCheckPoint(DashCheckPoint)}
                style={{ height: '400px', width: '100%' }}
              />
            </div>
          </div>

          <div className="dashboard-search-section-CheckPoint">
            <h3 className="dashboard-barStatus">ESTATUS</h3>

            <DateRangePicker
              label1={'Desde'}
              label2={'Hasta'}
              onChange={value => handleChange({ name: 'dateSelect', value })}
            />

            <MultiSelectANTD
              placeholder="Trabajadores"
              options={DashCheckPoint.allStartff.map(staff => ({
                value: staff.id,
                label: `${staff.name || ''} ${staff.lastName || ''}`
              }))}
              value={search?.staffs}
              onChange={value => handleChange({ name: 'staffs', value })}
            />

            <MultiSelectANTD
              placeholder="Planes"
              options={[
                { label: 'Limpieza', value: 'Limpieza' },
                { label: 'Mantenimiento', value: 'Mantenimiento' },
                { label: 'Seguridad', value: 'Seguridad' }
              ]}
              value={search?.routinesType}
              onChange={value => handleChange({ name: 'routinesType', value })}
            />

            <MultiSelectANTD
              placeholder="Estados"
              options={StatusFilter}
              value={search?.status}
              onChange={value => handleChange({ name: 'status', value })}
            />

            <ExportToExcel
              filteredReports={DashCheckPoint.dashCheckReport}
              date={search?.dateSelect}
            />
          </div>

          <div className="tickets-container">
            <div className="Check-header">
              <div className="Check-info-header">
                <span className="Check-progress-svg"></span>
                <span className="Check-namePlan">PLAN</span>
                <span className="Check-status">ESTADO</span>
                <span className="Check-progress">AVANCE</span>
                <span className="Check-nameStaff">TRABAJADOR</span>
                <span className="Check-nameLastPlan">LOCACIÓN</span>
                <span className="Check-AddressLastPlan">DIRECCIÓN</span>
                <span className="Check-lastUpdateTime">HORA</span>
                <span className="Check-TimeOut">T.O</span>
                <span className="Check-lastPhoto">FOTO</span>
                <div className="Check-actions">ACCIONES</div>
              </div>
            </div>

            {DashCheckPoint.dashCheckReport.map((report, index) => (
              <div key={index} className="Check">
                <div className="Check-info">
                  <span className="Check-progress-svg">{statusSvg[report.status]}</span>
                  <span className="Check-namePlan">{report.namePlan}</span>
                  <span className="Check-status">
                    {status[report.status]} <br /> {report.recordReport}
                  </span>
                  <span className="Check-progress">
                    <>
                      <LightTooltip
                        title="Acciones Plan"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.T_actions || 0} / </p>
                      </LightTooltip>

                      <LightTooltip
                        title="Acciones realizadas"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.R_actions || 0} / </p>
                      </LightTooltip>

                      <LightTooltip title="Eficiencia" followCursor TransitionComponent={Fade}>
                        <p className="client-info">{report.P_action || 0}%</p>
                      </LightTooltip>
                    </>
                  </span>
                  <span className="Check-nameStaff">{report.nameStaff}</span>
                  <span className="Check-nameLastPlan">{report.nameLastPlan}</span>
                  <span className="Check-AddressLastPlan">{report.AddressLastPlan}</span>
                  <span className="Check-lastUpdateTime">{report.lastUpdateHour}</span>

                  <span className="Check-progress">
                    <>
                      <LightTooltip
                        title="Tiempos fuera"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.C_TOut || 0} / </p>
                      </LightTooltip>

                      <LightTooltip
                        title="T.O Acumulado"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.C_OutMS || 0}</p>
                      </LightTooltip>
                    </>
                  </span>

                  <PhotoTooltip
                    photos={[report.lastPhoto]}
                    altText={report.nameLastPlan}
                    altPhoto="/images/logo_inicio.png"
                    className="Check-photo"
                  />
                  {Actions && (
                    <div className="Check-actions">
                      {<Actions id={report.id} state={report.status} />}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
