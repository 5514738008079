import React, { FC, useEffect, useState } from 'react'

import './style.scss'
import {
  typeMessage,
  typeMessageCreate,
  Select_Option_Resident
} from '../../types/Message_center'
import { MyTextArea } from '../../components/Form/labels/MyTextArea'
import { MultiSelectANTD } from '../../components/Form/multiSelet'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { firmate_aws, get_all_Residents, sendMessageWhatsapp } from '../../services/Message'
import FileUpload from '../../components/Form/FileUpload'
import { useLoading } from '../../Provider/LoadingProvider'
import { LightTooltip } from '../../components/tooltips/text'
import { Fade } from '@mui/material'
import { Notifications, NotificationsOff, Close } from '@mui/icons-material'
import Modal from '../../components/GeneralComponents/Modal'
import { MiniLoadingMessage } from './MiniLoadingMessage'

const defaultFormState: typeMessageCreate = {
  text: '',
  title: '',
  destines: []
}

interface typeModalProps {
  selected?: typeMessage
  onClose: () => void
}
export const ModalCreateMenssage: FC<typeModalProps> = ({ selected, onClose }) => {
  const { startLoading, stopLoading } = useLoading()

  const [formState, setFormState] = useState<typeMessageCreate>(defaultFormState)
  const [isNewMessage, setIsNewMessage] = useState<boolean>(true)
  const [ID_Message, setID_Message] = useState<string>()
  const [allResidents, setAllResidents] = useState<Select_Option_Resident[]>([])
  const [isopenModal, setisopenModal] = useState<boolean>(false)

  const [errorstatus, seterrorstatus] = useState<{
    MyTextArea?: string
    file?: string
    select1?: string
    select2?: string
    [key: string]: string | undefined // Firma de índice
  }>({})

  useEffect(() => {
    if (selected) {
      setIsNewMessage(false)
      getResidentes(selected.destines)
    } else {
      getResidentes()
    }
  }, [selected])

  const closeModal = () => {
    setisopenModal(false)
    onClose()
  }

  const getResidentes = async (destines?: string[]) => {
    const data = await get_all_Residents({ search: destines })
    setAllResidents(data)
  }

  const handleChange = <K extends keyof typeMessageCreate>(params: {
    name: K
    value: typeMessageCreate[K]
  }) => {
    setFormState(prevSearch => ({
      ...prevSearch, // Sobrescribir con valores existentes si están definidos
      [params.name]: params.value // Actualizar el campo específico
    }))
  }

  const handleSelectDestines = (newDestine: string[]) => {
    if (!isNewMessage) return //no se hace cambios si no es nuevo

    if (formState?.type === 'Express') {
      const newvalue = newDestine[newDestine.length - 1]
      handleChange({ name: 'destines', value: newvalue ? [newvalue] : [] })
    } else {
      handleChange({ name: 'destines', value: newDestine })
    }
  }

  const handleFileChange = (file: File | undefined) => {
    if (file) {
      const allowedTypes = [
        'audio/mpeg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //Excel
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // DOCX
      ]
      const maxFileSize = 16 * 1024 * 1024 // 16 MB

      // Validar tipo de archivo
      if (!allowedTypes.includes(file.type)) {
        seterrorstatus(last => ({
          ...last,
          file: 'Tipo de archivo no permitido. Suba una imagen, video o documento compatible.'
        }))

        handleChange({ name: 'file', value: undefined })
        return
      }

      // Validar tamaño del archivo
      if (file.size > maxFileSize) {
        seterrorstatus(last => ({
          ...last,
          file: 'El archivo supera el límite de 16 MB.'
        }))
        handleChange({ name: 'file', value: undefined })
        return
      }

      handleChange({ name: 'file', value: file })
    } else {
      handleChange({ name: 'file', value: undefined })
    }
  }

  const handleType = (value: typeMessageCreate['type']) => {
    if (value === 'All') {
      const idsResident = allResidents.map(R => R.value)

      handleChange({ name: 'destines', value: idsResident })
    } else {
      handleChange({ name: 'destines', value: [] })
    }

    handleChange({ name: 'type', value })
  }

  const sendWhatsapp = async () => {
    if (!isNewMessage) return
    try {
      startLoading()
      const errors: Record<string, string> = {}

      // Validaciones
      if (!formState?.text || formState.text.length <= 10) {
        errors.MyTextArea = 'Se requiere un mínimo de 10 caracteres.'
      }

      if (!formState?.title || formState.title.length <= 5) {
        errors.title = 'Se requiere un mínimo de 5 caracteres para el titulo.'
      }

      if (!formState?.type) {
        errors.select1 = 'Se requiere que seleccione un tipo.'
      }

      if (!formState?.destines || formState.destines.length < 1) {
        errors.select2 = 'Por favor selecciona un destino.'
      }

      // Actualizar el estado de errores
      seterrorstatus(prevErrors => {
        const updatedErrors = { ...prevErrors, ...errors }

        // Eliminar errores resueltos
        Object.keys(prevErrors).forEach(key => {
          if (!errors[key]) {
            delete updatedErrors[key]
          }
        })

        return updatedErrors
      })

      // Si hay errores, detener ejecución
      if (Object.keys(errors).length > 0) {
        return
      }

      // Lógica cuando no hay errores
      const data = new FormData()

      Object.entries({
        text: formState.text,
        title: formState.title,
        ...(formState.file ? { file: formState.file } : {}),
        ...(formState.type ? { type: formState.type } : {}),
        ...(formState.destines ? { destines: JSON.stringify(formState.destines) } : {})
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      setisopenModal(true)

      const id = await sendMessageWhatsapp(data)

      setID_Message(id)
    } catch (error) {
      setisopenModal(false)
    } finally {
      stopLoading()
    }
  }

  const downloadFile = async (filename: string, url?: string) => {
    try {
      if (!url) return
      startLoading()

      //consultamos una url firmada por aws
      const signedUrl = await firmate_aws({ url })

      // Hacer la solicitud al archivo
      const response = await fetch(signedUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream' // Indica que estás descargando un archivo binario
        }
      })

      if (!response.ok) {
        throw new Error('Error al descargar el archivo.')
      }

      // Obtener el blob del archivo
      const blob = await response.blob()

      // Crear una URL para el blob
      const urlBlob = window.URL.createObjectURL(blob)

      // Crear un enlace temporal para descargar el archivo
      const a = document.createElement('a')
      a.href = urlBlob

      // Obtener el nombre del archivo del enlace proporcionado

      a.download = filename // Nombre del archivo a descargar
      document.body.appendChild(a)
      a.click()

      // Limpiar el DOM
      document.body.removeChild(a)
      window.URL.revokeObjectURL(urlBlob)
    } catch (error) {
      console.error('Error al descargar el archivo:', error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="ModalCreate-container">
      <h3>CENTRAL DE MENSAJES IPASS</h3>

      <div className="ModalCreate-Form">
        <div className="fomrText">
          <MyTextArea
            error={errorstatus?.title ? true : false}
            colorBg={'#00000000'}
            placeholder="Titulo"
            BorderSize={2}
            borderColor
            value={selected?.title || formState?.title}
            onChange={value => handleChange({ name: 'title', value })}
            disabled={!isNewMessage}
            style={{ resize: 'none', alignSelf: 'center' }}
            rows={1}
          />

          <MyTextArea
            error={errorstatus?.MyTextArea ? true : false}
            personClassName="fullHeight"
            colorBg={'#00000000'}
            placeholder="REDACTA TU MENSAJE"
            BorderSize={2}
            borderColor
            value={selected?.text || formState?.text}
            onChange={value => handleChange({ name: 'text', value })}
            disabled={!isNewMessage}
            style={{ height: '100%', resize: 'none' }}
          />

          {isNewMessage ? (
            <FileUpload
              personClassName="FileArea"
              minHeight="100px"
              title="ADJUNTAR DOCUMENTO"
              label={'Arrastra o Selecciona'}
              value={formState.file}
              onChange={handleFileChange}
              accept="audio/mpeg,image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          ) : (
            <div className="FileArea">
              {selected?.nameFile && (
                <BorderIpass
                  style={{ width: '100%', height: '100%' }}
                  margin={2}
                  status={isNewMessage ? 'Normal' : 'Disable'}
                  onClick={() => downloadFile(selected?.nameFile, selected?.UrlFile)}
                >
                  <div className="text">{selected?.nameFile}</div>
                </BorderIpass>
              )}
            </div>
          )}
        </div>

        <div className="selects">
          <BorderIpass
            margin={2}
            status={errorstatus?.select1 ? 'Error' : !isNewMessage ? 'Disable' : 'Normal'}
            personClassName="selectType"
          >
            <MultiSelectANTD
              colorBg={'#00000000'}
              style={{ width: '100%' }}
              unique
              placeholder="SELECCIONA TIPO"
              options={[
                { label: 'TICKET EXPRESS', value: 'Express' },
                { label: 'SELECTIVO', value: 'select' },
                { label: 'COLECTIVO', value: 'All' }
              ]}
              value={selected?.type || formState?.type}
              onChange={handleType}
              disabled={!isNewMessage}
            />
          </BorderIpass>

          <BorderIpass
            margin={2}
            styleInner={{ width: '99%' }}
            status={errorstatus?.select2 ? 'Error' : !isNewMessage ? 'Disable' : 'Normal'}
          >
            <div className="multiselect">
              <MultiSelectANTD
                maxTagCount={undefined}
                showSearch
                colorBg={'#00000000'}
                style={{ width: '100%' }}
                placeholder="SELECCIONA DESTINO(S)"
                options={allResidents.map(option => ({
                  ...option,
                  key: option.label,
                  label: (
                    <LightTooltip
                      title={
                        option?.delegates?.length > 0
                          ? option?.delegates.map((delegate, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '0.5rem'
                                }}
                              >
                                {delegate.enableNotification ? (
                                  <Notifications
                                    style={{ color: 'green', fontSize: '1.1rem' }}
                                  />
                                ) : (
                                  <NotificationsOff
                                    style={{ color: 'red', fontSize: '1.1rem' }}
                                  />
                                )}
                                {` ${delegate.name} ${delegate.lastName}`}
                                {index < option?.delegates.length - 1 && <br />}
                              </div>
                            ))
                          : 'SIN DELEGADOS'
                      }
                      followCursor
                      TransitionComponent={Fade}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        {option.enableNotification ? (
                          <Notifications style={{ color: 'green', fontSize: '1.1rem' }} />
                        ) : (
                          <NotificationsOff style={{ color: 'red', fontSize: '1.1rem' }} />
                        )}
                        {option.label}
                      </div>
                    </LightTooltip>
                  )
                }))}
                filterOption={(input, option) =>
                  typeof option?.key === 'string' &&
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                value={selected?.destines || formState?.destines}
                onChange={values => {
                  const ultimo = values.pop() // Sacamos el último elemento del array.
                  ultimo && values.unshift(ultimo)
                  handleSelectDestines(values) // Actualiza el estado con los valores reordenados
                }}
                allowClear
                menuItemSelectedIcon={<Close style={{ color: 'red', fontSize: '1rem' }} />}
              />
            </div>
          </BorderIpass>

          <div className="bottomAndInfo">
            <div className="info">
              {errorstatus &&
                Object.values(errorstatus).map(
                  (message, index) =>
                    message && ( // Solo mostrar mensajes existentes
                      <p key={index}>{message}</p>
                    )
                )}
            </div>
            <BorderIpass
              style={{ width: '100%', height: '50px' }}
              margin={2}
              status={isNewMessage ? 'Normal' : 'Disable'}
              personClassName="button"
              onClick={sendWhatsapp}
            >
              <div className="text">ENVIAR MENSAJE</div>
            </BorderIpass>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isopenModal}
        borderColor={true}
        onClose={closeModal}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '400px',
          height: '230px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <MiniLoadingMessage onClose={onClose} ID_Message={ID_Message} />
      </Modal>
    </div>
  )
}
