import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'

export interface ReportModalProps {
  onClose: () => void
  ID_Message?: string
}

export const MiniLoadingMessage: React.FC<ReportModalProps> = ({ onClose, ID_Message }) => {
  const [processMsg, setProcessMsg] = useState('')

  const { SocketWhats } = useAuth()

  useEffect(() => {
    if (ID_Message && SocketWhats) {
      SocketWhats.on(`send_MSG-${ID_Message}`, (message: string) => {
        setProcessMsg(message)
      })
      SocketWhats.on(`send_MSG-${ID_Message}-Finish`, () => {
        onClose()
      })
    }
  }, [ID_Message])

  return (
    <div className="miniDeleteLoading">
      <div className="loading">
        <div className="loading-circle"></div>
      </div>
      <p>{`Procesando mensajes: ${processMsg}`}</p>
      <p>{`Puedes cerrar esta ventana , no afectará el envio de mensajes.`}</p>
      <div className="buttonContainer">
        <div className={`button ${'borderAnimateIpass'} `} onClick={onClose}>
          <div className="inner">ENTENDIDO</div>
        </div>
      </div>
    </div>
  )
}
