// src/services/CheckPoints.ts
import { CheckPointsLocation, PreViewQrCheckpoint } from '../types/CheckPoints_Location'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const createCheckPointsLocation = async (
  params: FormData
): Promise<CheckPointsLocation> => {
  try {
    const response = await axiosInstance.post('/api/CheckPoints', params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de CheckPointsLocation:', error)
    throw error
  }
}

export const updateCheckPointsLocation = async (
  id: string,
  params: FormData
): Promise<CheckPointsLocation> => {
  try {
    const response = await axiosInstance.post(`/api/CheckPoints/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la actualización de CheckPointsLocation:', error)
    throw error
  }
}

export const listCheckPoints = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<CheckPointsLocation>> => {
  try {
    const response = await axiosInstance.get(`/api/CheckPoints/byClient/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de CheckPointsLocationes:', error)
    throw error
  }
}

export const getAllCheckPoints = async (
  id: string,
  type: 'limpieza' | 'mantenimiento' | 'Seguridad'
): Promise<CheckPointsLocation[]> => {
  try {
    const response = await axiosInstance.get(
      `/api/CheckPoints/getAllCheckPoints/${id}/${type}`
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de getAllCheckPoints:', error)
    throw error
  }
}

export const deleteCheckPointsLocation = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/CheckPoints/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar CheckPoints Location:', error)
    throw error
  }
}

export const regenerateCode = async (id: string): Promise<{ qr: string }> => {
  try {
    const response = await axiosInstance.post(`/api/CheckPoints/regenerate_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar CheckPointsLocation:', error)
    throw error
  }
}

export const GetDataPreviewQrCheckPoint = async (id: string): Promise<PreViewQrCheckpoint> => {
  try {
    const response = await axiosInstance.get(`/api/CheckPoints/pre_view_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de CheckPointsLocationes:', error)
    throw error
  }
}

export const checkPointsQRs = async (ids: string[]): Promise<CheckPointsLocation[]> => {
  try {
    const response = await axiosInstance.patch(
      `/api/CheckPoints/format_qrs_donwload/`,
      { ids },
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la actualización de CheckPointsLocation:', error)
    throw error
  }
}
