import React, { useEffect, useState } from 'react'
import { CheckInProvider } from '../../Provider/CheckInProvider'
import CheckInDetail from '../../components/CheckIn/CheckInDetail'
import ModalCamera from '../../components/CheckIn/ModalCamera'
import TableCheckIn from '../../components/Tables/CheckIn/TableCheckIn'
import './style.scss'
import FilesWachs from '../../components/CheckIn/SocketNotifications/filesWachs'
import { useAuth } from '../../Provider/AuthProvider'
import { Select_Option_Resident } from '../../types/Message_center'
import { ListDataResident } from '../../services/entries'
const CheckIn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allResidents, setAllResidents] = useState<Select_Option_Resident[]>([])

  const { user } = useAuth()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      if (!user?.clientId) return

      const residents = await ListDataResident({})

      setAllResidents(residents)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <CheckInProvider>
      <div className="homeContainer">
        <div className="containerTitle">
          <h4>Check-in</h4>
          <p className="subtitle">Última identidad</p>
        </div>

        <CheckInDetail />
        <TableCheckIn handleOpenModal={handleOpenModal} />
        <ModalCamera
          isOpen={isModalOpen}
          allResidents={allResidents}
          onClose={handleCloseModal}
          onOpen={handleOpenModal}
        />
      </div>
      {user?.role === 'reception' && <FilesWachs />}
    </CheckInProvider>
  )
}

export default CheckIn
