import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './style.scss'
import { entries } from '../../types/entries'
import { IconButton } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { useLoading } from '../../Provider/LoadingProvider'
import { CheckCircleOutlined } from '@mui/icons-material'
import { ReactComponent as waitInLobby } from '../../assets/svg/waitInLobby.svg'
import { ReactComponent as leaveInLobby } from '../../assets/svg/leaveInLobby.svg'
import { ReactComponent as noLeaveInLobby } from '../../assets/svg/noLeaveInLobby.svg'
import { ReactComponent as revolvingDoor } from '../../assets/svg/revolvingDoor.svg'
import { DownloadPdfOneEntry } from '../../services/entries'

import Icon, {
  ClockCircleOutlined,
  CloseCircleOutlined,
  DislikeOutlined,
  EyeOutlined
} from '@ant-design/icons'

import { Image } from 'antd'
import moment from 'moment'
import Modal from '../../components/GeneralComponents/Modal'
import { BorderIpass } from '../../components/BorderIpass/Index'

export interface Props {
  Entrie?: entries
}

export const PreViewCheckIn: React.FC<Props> = ({ Entrie }) => {
  const { startLoading, stopLoading } = useLoading()
  const [modalPfd, setModalPfd] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const handlePdf = async (id?: string) => {
    if (id) {
      try {
        startLoading()
        console.log(id)
        const dataPdf64 = await DownloadPdfOneEntry(id) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64, 'application/pdf')

        // Crea un enlace para ver el PDF
        const url = URL.createObjectURL(pdfBlob)
        setPdfUrl(url)

        // Abre el modal
        setModalPfd(true)
      } catch (error) {
      } finally {
        stopLoading()
      }
    }
  }

  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const handleModalClose = () => {
    setModalPfd(false)
    setPdfUrl('') // Limpia la URL del PDF
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    WAITING: <ClockCircleOutlined style={{ color: 'yellow' }} />,
    APPROVED: <CheckCircleOutlined style={{ color: 'green' }} />,
    REJECT: <DislikeOutlined style={{ color: 'red' }} />,
    RECESSION: <Icon component={waitInLobby} />,
    LEAVE: <Icon component={leaveInLobby} />,
    NO_LEAVE: <Icon component={noLeaveInLobby} />,
    UNANSWERED: <CloseCircleOutlined style={{ color: 'red' }} />
  }

  return (
    <div className="ContainerPreView">
      <div className="header">
        <h3> {`FICHA VISITANTE - ${Entrie?.nameClient}`}</h3>
        <IconButton onClick={() => handlePdf(Entrie?.id)}>
          <PictureAsPdfIcon style={{ color: 'white' }} />
        </IconButton>
      </div>
      <div className="dataContainer">
        <div className="data1">
          <p>
            {Entrie?.name || Entrie?.lastName
              ? `${Entrie?.name} ${Entrie?.lastName}`
              : 'SIN NOMBRES'}
          </p>
          <p>{Entrie?.DNI}</p>
          <p>{`${Entrie?.brand} ${Entrie?.origin}`}</p>
        </div>
        <div className="data2">
          <p>{`${Entrie?.destination}`} </p>
          <div className="datasvg">
            {Entrie?.approved ? statusSvg[Entrie?.approved] : statusSvg.WAITING}
            <p>{`${Entrie?.nameAutorizeIn}`}</p>
          </div>
        </div>
      </div>
      <div className="photos">
        <BorderIpass style={{ width: '30%' }}>
          <Image
            preview={{
              mask: (
                <div>
                  <EyeOutlined style={{ marginRight: '5px' }} />
                  <span>Ver</span>
                </div>
              )
            }}
            wrapperStyle={{ width: '100%', height: '100%' }}
            src={Entrie?.photo || '/images/logo_inicio.png'}
            className="image_photo "
          />
        </BorderIpass>

        <BorderIpass style={{ width: '30%' }}>
          <Image
            preview={{
              mask: (
                <div>
                  <EyeOutlined style={{ marginRight: '5px' }} />
                  <span>Ver</span>
                </div>
              )
            }}
            wrapperStyle={{ width: '100%', height: '100%' }}
            src={Entrie?.DNI_A || '/images/logo_inicio.png'}
          />
        </BorderIpass>

        <BorderIpass style={{ width: '30%' }}>
          <Image
            preview={{
              mask: (
                <div>
                  <EyeOutlined style={{ marginRight: '5px' }} />
                  <span>Ver</span>
                </div>
              )
            }}
            wrapperStyle={{ width: '100%', height: '100%' }}
            src={Entrie?.DNI_B || '/images/logo_inicio.png'}
          />
        </BorderIpass>
      </div>
      <div className="status">
        <div className="checkIn">
          <p>{moment(Entrie?.dateIn).format('h:mm a')}</p>
          <p>{moment(Entrie?.dateIn).format('DD/MM/YYYY')}</p>
          <p>{Entrie?.cameraIn}</p>
        </div>
        <div className="checkSVG">
          <Icon component={revolvingDoor} />
        </div>
        <div className="checkOut">
          <p>{Entrie?.dateOut ? moment(Entrie?.dateOut).format('h:mm a') : ''}</p>
          <p>{Entrie?.dateOut ? moment(Entrie?.dateOut).format('DD/MM/YYYY') : ''}</p>
          <p>{Entrie?.cameraOut || ''}</p>
        </div>
      </div>

      <Modal
        isOpen={modalPfd}
        styleContainer={{
          width: ' 100%',
          height: ' 100%',
          padding: '0px',
          background: 'rgb(0 0 0 / 0%)',
          overflow: 'hidden'
        }}
        styleContainerContent={{ padding: '0 60px', height: '100%', maxHeight: '100vh' }}
        onClose={handleModalClose}
      >
        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="99%" // Ajusta la altura según tus necesidades
        >
          <Link to={pdfUrl} target="_blank" rel="noopener noreferrer">
            Descargar PDF
          </Link>
        </object>
      </Modal>
    </div>
  )
}
