import React, { FC, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Image } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import './style.scss'
import { PaymentDetaild } from '../../../types/Payments'
import moment from 'moment'
import { BorderIpass } from '../../BorderIpass/Index'
import Modal from '../../GeneralComponents/Modal'
import { LabelANTD } from '../../Form/labels/oneLine'
import { processPayment } from '../../../services/Payments'

export const ModalEditPayment: FC<{
  IsEdit: boolean
  select: PaymentDetaild
  onClose: () => void
}> = ({ IsEdit, select, onClose }) => {
  const { startLoading, stopLoading } = useLoading()
  const altPhoto = 'images/logo_inicio.png'
  const [isRejected, setIsRejected] = useState<boolean>(false)
  const [formState, setFormState] = useState<string>('')
  const [error, setError] = useState(false)

  const handleApproved = async () => {
    try {
      startLoading()
      await processPayment({ paymentId: select.id, type: 'aprovado' })
      handleClosed()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
      handleClosed()
    }
  }

  const handleRejected = async () => {
    try {
      if (formState.length < 10) {
        setError(true)
        return
      }
      setError(false)
      startLoading()

      await processPayment({ paymentId: select.id, type: 'rechazado', message: formState })

      handleClosed()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleClosed = () => {
    setError(false)
    setIsRejected(false)
    onClose()
  }

  return (
    <div className="paymentsDetail-container">
      <>
        <div className="containerphoto">
          <div className="photo">
            <Image
              preview={{
                mask: (
                  <div>
                    <EyeOutlined />
                    <span>Ver</span>
                  </div>
                )
              }}
              wrapperStyle={{ width: '100%', height: '100%' }}
              style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0px' }}
              src={select.photo || altPhoto}
              className="image"
            />
          </div>
        </div>

        <div className="InfoModal">
          <div className="header">
            <p>{`${moment(select.createdAt).locale('es').format('DD/MM/YYYY h:mm:ss a')}`}</p>
            <p>Monto esperado Q {(select.amount * -1).toFixed(2)}</p>
          </div>
          <div className="buttom">
            <BorderIpass margin={4} onClick={() => setIsRejected(true)}>
              RECHAZAR
            </BorderIpass>

            <BorderIpass margin={4} onClick={handleApproved}>
              CONFIRMAR
            </BorderIpass>
          </div>
        </div>
      </>

      <Modal
        isOpen={isRejected}
        borderColor
        onClose={() => setIsRejected(false)}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '50%',
          height: '40%',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <div className="modalReject">
          <p>POR FAVOR DECRIBE EL MOTIVO DEL RECHAZO, ESTO SE LE ENVIARA AL RESIDENTE</p>
          <LabelANTD
            prefix="Motivo"
            borderColor
            value={formState}
            onChange={setFormState}
            error={error}
            disabled={false}
            labelError={'Se requiere minimo 10 caracteres'}
          />
          <div className="buttom">
            <BorderIpass margin={4} onClick={handleRejected}>
              RECHAZAR
            </BorderIpass>
          </div>
        </div>
      </Modal>
    </div>
  )
}
