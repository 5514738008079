// ModalCamera.tsx
import SettingsIcon from '@mui/icons-material/Settings'
import { IconButton } from '@mui/material'
import React, { FC, useState, useRef, useEffect } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useCamera } from '../../../Provider/CameraProvider'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createVisitor } from '../../../services/visitor'
import { printQRCode } from '../../../utils/printer'
import Button from '../../Form/Button'
import Modal from '../../GeneralComponents/Modal'
import PopUp from '../../GeneralComponents/PopUp'
import ShowQr from '../../ShowQr'
import FormModalCamera from '../FormModalCamera'
import NewCamera from '../NewCameraComponent'
import PopUpConfig from '../PopUpConfig'
import { Toaster, toast } from 'sonner'
import { listBrandsAll } from '../../../services/brand'
import { brand } from '../../../types/brand'
import './styles.scss'
import { Select_Option_Resident } from '../../../types/Message_center'
import { LocalHospital } from '@mui/icons-material'

type ModalProps = {
  isOpen: boolean
  allResidents: Select_Option_Resident[]
  onClose: () => void
  onOpen: () => void
}

export type FormType = {
  brand?: string
  destinationCode?: string
  comment?: string
}

const ModalCamera: FC<ModalProps> = ({ isOpen, onClose, onOpen, allResidents }) => {
  const [showCameraConfigPopup, setShowCameraConfigPopup] = useState(false)
  const [brands, setBrands] = useState<brand[]>([])
  const [isEmergency, setIsEmergency] = useState<boolean>(false)

  // Preselecciona la marca si está en localStorage
  useEffect(() => {
    const preSelectBrand = localStorage.getItem('preSelectBrand')
    if (preSelectBrand && preSelectBrand !== 'undefined') {
      handleInputChange({ name: 'brand', value: preSelectBrand })
    }
  }, [])

  const [images, setImages] = useState<{
    photo: File | null
    DNI_A: File | null
    DNI_B: File | null
  }>({
    photo: null,
    DNI_A: null,
    DNI_B: null
  })
  const [showPopup, setShowPopup] = useState(false)
  const [formValues, setFormValues] = useState<FormType>()
  const selectRef = useRef<HTMLInputElement>(null)
  const { cameraDNI, cameraPhoto } = useCamera()

  const [showQr, setShowQr] = useState('')
  const { user, token } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    if (!brands.length /*  || !destine.length */) {
      getData()
    }
  }, [])

  const getData = async () => {
    try {
      const brands = await listBrandsAll(token)
      setBrands(brands)
    } catch (error) {
      console.error(error)
    }
  }

  // Maneja el cambio de valores del formulario
  const handleInputChange = ({ name, value }: { name: string; value: string }) => {
    setFormValues({
      ...formValues,
      [name]: value
    })

    if (name === 'brand') {
      localStorage.setItem(`preSelectBrand`, value)
    }
  }

  const handleEntendidoClick = (openAgain?: boolean) => {
    setShowPopup(false)
    onClose()

    const preSelectBrand = localStorage.getItem('preSelectBrand')

    setFormValues({
      ...(preSelectBrand && preSelectBrand !== 'undefined' ? { brand: preSelectBrand } : {})
    })

    setImages({
      photo: null,
      DNI_A: null,
      DNI_B: null
    })

    if (openAgain) {
      setTimeout(() => {
        onOpen()
        if (selectRef.current) {
          selectRef.current.focus() // Enfocar el campo de texto automáticamente
        }
      }, 4000)
    }
  }

  const handleEmergencybutton = () => {
    if (isEmergency) {
      setFormValues(undefined)
    } else {
      setFormValues({
        ...formValues,
        brand: '0d0cdf7d-949c-4975-8573-7ce533c1f4fa',
        comment: 'Ingreso autorizado por medio de emergencia'
      })
    }

    setIsEmergency(!isEmergency)
  }

  const handleCheckIn = async () => {
    try {
      startLoading()
      const formData = new FormData()

      formValues &&
        Object.entries(formValues).forEach(([key, value]) => {
          formData.append(key, value)
        })

      Object.entries(images).forEach(([key, value]) => {
        formData.append(key, value as Blob)
      })
      formData.append('clientId', user?.clientId as string)

      const getData = async () => {
        try {
          const visitor = await createVisitor(formData, isEmergency, token)
          if (visitor) {
            toast.success('El check-in se registró correctamente', {
              description: `${visitor.name} ${visitor.lastName}`,
              position: 'top-right'
            })
          } else {
            toast.success('El check-in se registró correctamente', {
              position: 'top-right'
            })
          }
          return visitor
        } catch (error: any) {
          if (error?.response?.data?.detalles) {
            toast.warning(`${error?.response?.data?.detalles[0]}`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          } else if (error?.response?.status === 400) {
            toast.warning(`Falta información`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          } else {
            toast.error(`Error desconocido`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          }
        }
      }

      // Llamar a la función al montar el componente
      const entry = await getData()

      await printQRCode(entry.qrCode as string, entry, user?.client?.name || '')
      handleEntendidoClick(true)
      // showQrFn(visitor.qrCode)
      //setShowPopup(true)
    } catch (error) {
      console.error('API Error:', error)
    } finally {
      stopLoading()
    }
  }

  if (!isOpen) return null
  return (
    <>
      <Modal
        borderColor={true}
        styleContainer={{
          maxHeight: '600px',
          height: '100%',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleContainerContent={{
          padding: '5px',
          margin: '0px',
          height: '100%',
          maxHeight: '600px'
        }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="containerModalCamera">
          <div className="containerTitle">
            <div className="leftSection">
              <IconButton
                className="buttonConfiguracion"
                onClick={() => setShowCameraConfigPopup(true)}
              >
                <SettingsIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
            <div className="middleSection">
              <h2>CHECK-IN</h2>
            </div>
          </div>
          <div className="modal-sections">
            <FormModalCamera
              handleInputChange={handleInputChange}
              formValues={formValues}
              brands={brands}
              allResidents={allResidents}
            />

            <div className="modal-section">
              <NewCamera
                onSubmitImage={value => setImages((old: any) => ({ ...old, photo: value }))}
                text="Foto del rostro"
                deviceId={cameraPhoto ?? ''}
              />
              <NewCamera
                onSubmitImage={value => setImages((old: any) => ({ ...old, DNI_A: value }))}
                text="DNI frontal"
                deviceId={cameraDNI ?? ''}
              />
              <NewCamera
                onSubmitImage={value => setImages((old: any) => ({ ...old, DNI_B: value }))}
                text="DNI trasera"
                deviceId={cameraDNI ?? ''}
              />
            </div>
            <div className="button-section">
              <Button
                styleType="cancel"
                text="Cancelar"
                onClick={() => {
                  handleEntendidoClick(false)
                }}
              />

              <div className="emergency_button">
                <LocalHospital
                  style={{ color: 'red', fontSize: '2rem', cursor: 'pointer' }}
                  onClick={handleEmergencybutton}
                />
              </div>

              <Button
                disabled={!isEmergency && (!images.photo || !images.DNI_A || !images.DNI_B)}
                style={
                  !isEmergency && (!images.photo || !images.DNI_A || !images.DNI_B)
                    ? { background: 'gray', pointerEvents: 'none' }
                    : {}
                }
                text="→ Registrar check in"
                onClick={handleCheckIn}
              />
            </div>
          </div>
        </div>
      </Modal>
      {showPopup && (
        <PopUp
          buttonText="Entendido"
          description="El check-in se registró correctamente"
          title="Registro exitoso"
          handleOk={handleEntendidoClick}
        />
      )}
      {showCameraConfigPopup && (
        <PopUpConfig handleClose={() => setShowCameraConfigPopup(false)} />
      )}
      <ShowQr qr={showQr} isModalOpen={!!showQr} handleCloseModal={() => setShowQr('')} />
      <Toaster richColors />
    </>
  )
}

export default ModalCamera
