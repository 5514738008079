// Clients.tsx
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'
import Table, { TableColumn, enumTypeColumn } from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import EnableComponent from '../../components/Table/components/EnableComponent'
import { columnsClient } from '../../components/Tables/Clients/Columns'
import FormClient from '../../components/Tables/Clients/Form'
import { deleteClient, enableClient, listClient } from '../../services/clients'
import { Client } from '../../types/client'
import { Pagination } from '../../types/types'
import './styles.scss'
const Clients = () => {
  const [data, setData] = useState<Pagination<Client>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const { startLoading, stopLoading } = useLoading()
  const navigate = useNavigate()
  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getData(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getData('', 1)
    }
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  const onCloseModal = () => {
    setIsOpenModal(false)
  }
  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const clients = await listClient({
        page,
        search: searchValue
      })
      setData(clients)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = () => {
    setIsOpenModal(false)
    getData(search.length > 3 ? search : '', data.currentPage)
  }
  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }
  const handleView = (id: string) => {
    navigate(`/clients/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteClient(id)
      getData(search, data.currentPage)
    } catch (error) {
      console.error('Error al eliminar el administrador:', error)
    } finally {
      stopLoading()
    }
  }

  const Actions = ({ id }: { id: string }) => {
    return (
      <div className="buttonAction">
        <IconButton onClick={() => handleView(id)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  const handleChangeEnable = ({
    value,
    id,
    column
  }: {
    value: boolean
    id: string
    column: string
  }) => {
    try {
      setData(last => {
        const updatedData = last.data.map(client =>
          client.id === id ? { ...client, [column]: value } : client
        )

        return {
          ...last,
          data: updatedData
        }
      })
      const form = new FormData()
      form.append(column, value.toString())
      enableClient(id, form)
    } catch (error) {
      console.error(error)
    } finally {
    }
  }
  const columnsClientEnable: TableColumn[] = [
    ...columnsClient,
    {
      title: 'Habilitado',
      dataIndex: 'enable',
      key: 'enable',
      type: enumTypeColumn.custom,
      CustomRender: ({ id, item }: { id: string; item?: Client }) => {
        if (!item) return null
        return (
          <EnableComponent
            title={`¿Estás seguro de ${item.enable ? 'Deshabilitar' : 'habilitar'}?`}
            enable={item.enable}
            onUpdate={() => handleChangeEnable({ value: !item?.enable, id, column: 'enable' })}
          />
        )
      }
    },
    {
      title: 'Mora',
      dataIndex: 'enable',
      key: 'enable',
      type: enumTypeColumn.custom,
      CustomRender: ({ id, item }: { id: string; item?: Client }) => {
        if (!item) return null
        return (
          <EnableComponent
            title={`ESTADOS DE MORA`}
            description={`¿Estás seguro de ${
              item.mora ? 'desactivar' : 'activar'
            } las notificaciones?`}
            activeColor="#ff0000"
            ColorDiscative="#16bd00"
            acceptText={`${item.mora ? 'Desactivar' : 'Activar'}`}
            enable={item.mora}
            onUpdate={() => handleChangeEnable({ value: !item?.mora, id, column: 'mora' })}
          />
        )
      }
    }
  ]
  return (
    <>
      <div className="clientsContainer">
        <div className="title">
          <p>Clientes</p>
        </div>
        <div className="tableContainer">
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            handleOpenModal={handleOpenModal}
            columns={columnsClientEnable}
            Actions={Actions}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <FormClient onSubmit={handleSubmit} />
      </Modal>
    </>
  )
}

export default Clients
