import React, { useEffect, useState } from 'react'
import './style.scss'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { useLoading } from '../../Provider/LoadingProvider'
import { Toaster, toast } from 'sonner'
import { getPenaltyDate, savePenaltyDate } from '../../services/manageress'

const InitValues = { valuePorcent: '1', valueForCuota: '5.00' }

const Component: React.FC = () => {
  const { startLoading, stopLoading } = useLoading()

  const [value, setValue] = useState(InitValues)
  const [fee, setFee] = useState<'fixed' | 'percentage'>()
  const [timeSelect, setTimeSelect] = useState<'diary' | 'monthly'>()

  useEffect(() => {
    const data = async () => {
      const data = await getPenaltyDate()
      if (!data) return

      const { value, fee, timeSelect } = data
      const select = fee === 'percentage' ? 'valuePorcent' : 'valueForCuota'

      setValue(last => ({ ...last, [select]: value }))
      setFee(fee)
      setTimeSelect(timeSelect)
    }

    data()
  }, [])

  const handleSave = async () => {
    try {
      if (!fee || !timeSelect || !value) {
        toast.error('FALTA CONFIGURAR ALGUN PARÁMETRO')

        return
      }
      const configSave = {
        fee,
        timeSelect,
        value: fee === 'percentage' ? value.valuePorcent : value.valueForCuota
      }
      startLoading()
      const Result = await savePenaltyDate(configSave)
      if (Result) {
        toast.success('PARÁMETROS GUARDADOS CORRECTAMENTE')
      } else {
        toast.error('OCURRIO UN ERROR, PORFAVOR INTENTA MAS TARDE')
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  // Manejar cambios en el input
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    select: 'valuePorcent' | 'valueForCuota'
  ) => {
    const inputValue = e.target.value

    // Si el campo está vacío, asignar un valor por defecto (por ejemplo, 1)
    if (inputValue === '') {
      setValue(last => ({ ...last, [select]: inputValue as unknown as number }))
      return
    }

    if (/^\d*\.?\d*$/.test(inputValue)) {
      const numericValue = parseFloat(inputValue)

      // Formatear a string con 2 decimales
      const formattedValue =
        select === 'valueForCuota'
          ? numericValue.toFixed(2) // Siempre 2 decimales
          : numericValue.toString() // Sin necesidad de formateo para otros campos

      // Actualizar el estado
      setValue(last => ({
        ...last,
        [select]: formattedValue
      }))
    }
  }

  // Incrementar el valor
  const handleIncrement = (select: 'valuePorcent' | 'valueForCuota') => {
    setValue(prevValue => {
      const numericValue = parseFloat(prevValue[select])
      const sum = isNaN(numericValue) ? 1 : Math.max(1, numericValue + 1)
      // Formatear a string con 2 decimales
      const formattedValue =
        select === 'valueForCuota'
          ? sum.toFixed(2) // Siempre 2 decimales
          : sum.toString() // Sin necesidad de formateo para otros campos

      return {
        ...prevValue,
        [select]: formattedValue
      }
    })
  }

  // Decrementar el valor
  const handleDecrement = (select: 'valuePorcent' | 'valueForCuota') => {
    setValue(prevValue => {
      const numericValue = parseFloat(prevValue[select])
      const sum = isNaN(numericValue) ? 1 : Math.max(1, numericValue - 1)
      // Formatear a string con 2 decimales
      const formattedValue =
        select === 'valueForCuota'
          ? sum.toFixed(2) // Siempre 2 decimales
          : sum.toString() // Sin necesidad de formateo para otros campos

      return {
        ...prevValue,
        [select]: formattedValue
      }
    })
  }

  return (
    <div className="Container_penalty">
      <div className="title">
        <BorderIpass
          margin={0}
          status={'Normal'}
          style={{ ...styleBorderB, width: '60%' }}
          styleInner={{
            ...styleBorderB,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0.5rem'
          }}
        >
          <h4>CONFIGURACIÓN POLÍTICA DE MORA</h4>
          <p>Debes seleccionar opción A o B y configurar tu política</p>
        </BorderIpass>
      </div>
      <div className="Config_container">
        <div className="Options">
          <BorderIpass
            margin={'0'}
            status={'Normal'}
            styleInner={styleBorderR}
            style={{ ...styleBorderR }}
          >
            <div className=" Options section1">
              <BorderIpass
                personClassName={'button'}
                margin={3}
                status={fee === 'percentage' ? 'Disable' : 'Normal'}
                onClick={() => setFee('percentage')}
                styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
                style={LetterStyleLong}
              >
                A
              </BorderIpass>
              <BorderIpass
                personClassName={'button'}
                margin={4}
                status={'Normal'}
                onClick={() => setFee('percentage')}
                styleInner={{ ...SelectStyles, width: '150px' }}
              >
                PORCENTAJE
              </BorderIpass>
            </div>
          </BorderIpass>
          <div className="Options section2">
            <BorderIpass margin={4} status={'Normal'} styleInner={SelectStyles}>
              <div className="custom-number-input">
                <input
                  value={value.valuePorcent}
                  onChange={e => handleChange(e, 'valuePorcent')}
                  type="text" // Tipo texto para controlar mejor el input
                />
                <span>%</span>
                <div className="controls">
                  <button onClick={() => handleIncrement('valuePorcent')}>▲</button>
                  <button onClick={() => handleDecrement('valuePorcent')}>▼</button>
                </div>
              </div>
            </BorderIpass>
            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'percentage' && timeSelect === 'diary' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('diary')
                setFee('percentage')
              }}
              styleInner={SelectStyles}
            >
              POR DÍA
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'percentage' && timeSelect === 'monthly' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('monthly')
                setFee('percentage')
              }}
              styleInner={SelectStyles}
            >
              POR MES
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={'Normal'}
              styleInner={SelectStyles}
              onClick={handleSave}
            >
              <b>GUARDAR</b>
            </BorderIpass>
          </div>
        </div>

        <div className="Options">
          <BorderIpass
            margin={'0'}
            status={'Normal'}
            styleInner={styleBorderR}
            style={{ ...styleBorderR }}
          >
            <div className=" Options section1">
              <BorderIpass
                personClassName={'button'}
                margin={3}
                status={fee === 'fixed' ? 'Disable' : 'Normal'}
                onClick={() => setFee('fixed')}
                styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
                style={LetterStyleLong}
              >
                B
              </BorderIpass>
              <BorderIpass
                personClassName={'button'}
                margin={4}
                status={'Normal'}
                onClick={() => setFee('fixed')}
                styleInner={{ ...SelectStyles, width: '150px' }}
              >
                CUOTA FIJA
              </BorderIpass>
            </div>
          </BorderIpass>
          <div className="Options section2">
            <BorderIpass margin={4} status={'Normal'} styleInner={SelectStyles}>
              <div className="custom-number-input">
                <input
                  value={value.valueForCuota}
                  onChange={e => handleChange(e, 'valueForCuota')}
                  type="text" // Tipo texto para controlar mejor el input
                />
                <span>Q</span>
                <div className="controls">
                  <button onClick={() => handleIncrement('valueForCuota')}>▲</button>
                  <button onClick={() => handleDecrement('valueForCuota')}>▼</button>
                </div>
              </div>
            </BorderIpass>
            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'fixed' && timeSelect === 'diary' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('diary')
                setFee('fixed')
              }}
              styleInner={SelectStyles}
            >
              POR DÍA
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'fixed' && timeSelect === 'monthly' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('monthly')
                setFee('fixed')
              }}
              styleInner={SelectStyles}
            >
              POR MES
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={'Normal'}
              styleInner={SelectStyles}
              onClick={handleSave}
            >
              <b>GUARDAR</b>
            </BorderIpass>
          </div>
        </div>
      </div>
      <div className="title">
        <BorderIpass
          margin={0}
          status={'Normal'}
          style={{ ...styleBorderB, width: '60%' }}
          styleInner={{
            ...styleBorderB,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0.5rem'
          }}
        >
          <h4>POLÍTICA APLICADA</h4>
          <p>Estos ejemplos ilustran como se aplicará tu política de mora</p>
        </BorderIpass>
      </div>

      <div className="example">
        <div className="Example_div">
          <div>
            <BorderIpass margin={2} status={'Normal'} styleInner={LetterStyle}>
              A
            </BorderIpass>
          </div>

          <p>
            Cuota Mes por{' '}
            <b>
              <u>Q500.00</u>
            </b>{' '}
            que con política mora por{' '}
            <b>
              <u>PORCENTAJE POR MES</u>{' '}
            </b>{' '}
            hace que la mora sea de:
            <b>
              {' '}
              <u>Q25</u>{' '}
            </b>{' '}
            para hacer que cargos cierren por{' '}
            <b>
              {' '}
              <u>Q525.00</u>
            </b>
          </p>
        </div>

        <div className="Example_div">
          <div>
            <BorderIpass margin={2} status={'Normal'} styleInner={LetterStyle}>
              B
            </BorderIpass>
          </div>
          <p>
            Cuota Mes por{' '}
            <b>
              <u>Q500.00</u>
            </b>{' '}
            que con política mora por{' '}
            <b>
              <u>CUOTA FIJA POR DÍA</u>{' '}
            </b>{' '}
            (10 días retraso) sea de:
            <b>
              {' '}
              <u>Q50</u>{' '}
            </b>{' '}
            para hacer que cargos cierren por{' '}
            <b>
              {' '}
              <u>Q550.00</u>
            </b>
          </p>
        </div>
      </div>
      <Toaster richColors closeButton position="top-right" />
    </div>
  )
}

export default Component

const LetterStyle: React.CSSProperties = {
  alignItems: 'center',
  width: '30px',
  height: '30px',
  color: 'white'
}

const LetterStyleLong: React.CSSProperties = {
  alignItems: 'center',
  borderRadius: '50%',
  fontSize: '40px',
  fontWeight: 'bold',
  color: 'white'
}

const SelectStyles: React.CSSProperties = {
  margin: '4px',
  height: '50px',
  width: '110px',
  color: 'white',
  alignItems: 'center'
}

const styleBorderB: React.CSSProperties = {
  borderRadius: 0,
  margin: 0,
  marginBottom: '5px'
}

const styleBorderR: React.CSSProperties = {
  borderRadius: 0,
  margin: 0,
  marginRight: '3px'
}
