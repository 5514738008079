import React from 'react'
import XLSX from 'xlsx-js-style'
import { Button, ConfigProvider } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'
import { DashCheckReport } from '../../../types/dashboard'
import { useLoading } from '../../../Provider/LoadingProvider'
import { useAuth } from '../../../Provider/AuthProvider'
import moment from 'moment'

interface ExportToExcelProps {
  filteredReports: DashCheckReport[]
  style?: React.CSSProperties
  date?: string[]
}

interface GroupedData {
  nameStaff: string
  T_actions: number
  R_actions: number
  allPlanes: number
}

const ExportToExcel: React.FC<ExportToExcelProps> = ({ filteredReports, style, date }) => {
  const { startLoading, stopLoading } = useLoading()
  const { user } = useAuth()

  const handleExport = async () => {
    try {
      startLoading()

      const date1 = date ? moment(date[0]).format('DD-MM-YYYY') : ''
      const date2 = date ? moment(date[1]).format('DD-MM-YYYY') : ''
      const textDate = date ? `${date1} - ${date2}` : ''
      // Obtener datos de encabezado
      const dataHeader = filteredReports.reduce(
        (acc, item) => {
          const { status } = item

          if (status === 'inProgress') acc.inProgress += 1
          if (status === 'notTaken') acc.notTaken += 1
          if (status === 'finalized') acc.finalized += 1
          acc.all += 1

          return acc
        },
        { all: 0, inProgress: 0, notTaken: 0, finalized: 0 }
      )

      const grouped = filteredReports.reduce((acc, item) => {
        const { nameStaff, T_actions, R_actions } = item

        if (!acc[nameStaff]) {
          acc[nameStaff] = {
            nameStaff,
            T_actions: 0,
            R_actions: 0,
            allPlanes: 0
          }
        }

        acc[nameStaff].T_actions += T_actions
        acc[nameStaff].R_actions += R_actions
        acc[nameStaff].allPlanes += 1

        return acc
      }, {} as { [key: string]: GroupedData })

      const newFormatData = Object.values(grouped)

      // Crear datos de la tabla
      const data = newFormatData.map(report => {
        return {
          TRABAJADOR: report.nameStaff,
          'PLANES TOMADOS': report.allPlanes,
          'ACCIONES TOMADAS': report.T_actions,
          'ACCIONES REALIZADAS': report.R_actions,
          '% DE EFICIENCIA': `${
            Math.round((report.R_actions / report.T_actions) * 100) || 0
          } %`
        }
      })

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet([]) // Crear hoja vacía para llenarla manualmente

      // Combinar celdas A1 y B1 para el título
      worksheet['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, // A1:C1
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // A2:C2
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } }, // A3:C3
        { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } }, // A4:C4
        { s: { r: 4, c: 0 }, e: { r: 4, c: 1 } }, // A5:C5
        { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } } // A6:C6
      ]

      // Colocar el título en la celda combinada A1:C1
      worksheet['A1'] = {
        v: `CLIENTE: ${user?.client?.name || ''} ${textDate}`,
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: 'center', vertical: 'center' }
        }
      }

      // Colocar información adicional en las filas siguientes
      worksheet['A2'] = {
        v: `CANTIDAD DE PLANES:`,
        s: { font: { bold: true } }
      }
      worksheet['C2'] = {
        v: `${dataHeader.all}`
      }

      worksheet['A3'] = {
        v: `PLANES EN PROCESO:`,
        s: { font: { bold: true } }
      }
      worksheet['C3'] = {
        v: `${dataHeader.inProgress}`
      }

      worksheet['A4'] = { v: `PLANES NO TOMADOS:`, s: { font: { bold: true } } }
      worksheet['C4'] = { v: `${dataHeader.notTaken}` }

      worksheet['A5'] = {
        v: `PLANES FINALIZADOS:`,
        s: { font: { bold: true } }
      }
      worksheet['C5'] = {
        v: `${dataHeader.finalized}`
      }

      worksheet['A6'] = {
        v: `EFICIENCIA:`,
        s: { font: { bold: true } }
      }

      worksheet['C6'] = {
        v: `${Math.round((dataHeader.finalized / dataHeader.all) * 100) || 0} %`
      }

      // Ajustar rango para que la primera fila de datos esté después del encabezado
      const startingRow = 8

      // Agregar los datos de la tabla comenzando en la fila startingRow
      XLSX.utils.sheet_add_json(worksheet, data, { origin: `A${startingRow}` })

      // Estilo de encabezado de columnas
      const headerCellStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '800080' } },
        alignment: { horizontal: 'center', vertical: 'center' }
      }

      // Ajustar el ancho de la columna A (150px) y otras columnas
      worksheet['!cols'] = [
        { wpx: 150 }, // Columna A con ancho 150px
        { wpx: 150 }, // Columna A con ancho 150px
        { wpx: 200 }, // Columna B con ancho 200px
        { wpx: 200 }, // Columna C con ancho 200px
        { wpx: 200 } // Columna E con ancho 200px
      ]

      // Aplicar estilos a los encabezados de la tabla en startingRow
      const range = XLSX.utils.decode_range(worksheet['!ref']!)

      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + startingRow
        if (worksheet[address]) {
          worksheet[address].s = headerCellStyle
        }
      }

      // Aplicar estilos a las celdas de datos
      const cellStyle = {
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } }
        }
      }

      for (let R = startingRow; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: R, c: C })
          if (worksheet[address]) {
            worksheet[address].s = {
              ...cellStyle,
              fill: { fgColor: { rgb: R % 2 === 0 ? 'FFFFFF' : 'E6E6FA' } } // Intercalar colores
            }
          }
        }
      }

      // Añadir hoja y descargar
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Reportes')
      XLSX.writeFile(
        workbook,
        `${user?.name}-Dashboard-${
          textDate ? textDate : moment(Date.now()).format('DD_MM_YYYY-h_mm')
        }.xlsx`
      )
    } catch (error) {
      console.error('Error al exportar:', error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div style={style}>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorBgContainer: 'rgba(56, 78, 183, 0.3)',
              colorBorder: '#484747',
              colorPrimary: 'white',
              hoverBorderColor: 'white',
              colorText: 'white',
              colorTextBase: 'white',
              optionSelectedColor: 'white',
              selectorBg: 'rgba(56, 78, 183, 0.3)',
              optionSelectedBg: 'rgba(56, 78, 183, 0.3)',
              multipleItemBg: 'rgba(56, 78, 183, 0.3)',
              colorIcon: 'white',
              colorIconHover: 'white',
              colorBorderBg: 'rgba(56, 78, 183, 0.3)',
              activeBorderColor: 'white'
            }
          },
          token: {
            colorBgBase: 'rgba(56, 78, 183, 0.3)',
            colorBgContainer: 'rgba(56, 78, 183, 0.3)',
            colorText: 'white',
            colorTextPlaceholder: 'white',
            colorIcon: 'white',
            colorIconHover: 'white',
            colorPrimaryHover: 'white',
            colorPrimaryTextHover: 'white',
            colorLinkActive: 'white',
            colorLink: 'white',
            colorLinkHover: 'white',
            colorPrimaryActive: 'white',
            colorPrimaryTextActive: 'white'
          }
        }}
      >
        <Button
          onClick={handleExport}
          icon={<ArrowDownOutlined />}
          style={{ height: '100%', borderColor: '#80808029' }}
        >
          Excel
        </Button>
      </ConfigProvider>
    </div>
  )
}

export default ExportToExcel
