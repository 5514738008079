import React from 'react'
import './style.scss'
import { BorderIpass } from '../../components/BorderIpass/Index'

export interface ReportModalProps {
  onSend: (x: any) => void
  onClose: () => void
  lastDate: string
}

export const Warning: React.FC<ReportModalProps> = ({ onClose, onSend, lastDate }) => {
  return (
    <div className="miniWarnig">
      <>
        <h3>¡¡CUIDADO!!</h3>

        <p>{`Estas a punto de cambiar la fecha de corte cuando esta ya pasó el ${lastDate}`}</p>

        <p>{`Esto puede causar cobros dobles`}</p>

        <div className="Bottom">
          <BorderIpass margin={3} onClick={onClose}>
            CANCELAR
          </BorderIpass>

          <BorderIpass margin={3} onClick={onSend}>
            CONTINUAR
          </BorderIpass>
        </div>
      </>
    </div>
  )
}
