import React, { FC } from 'react'
import './style.scss'

import { brand } from '../../../types/brand'
import { MyTextArea } from '../../Form/labels/MyTextArea'
import { Notifications, NotificationsOff } from '@mui/icons-material'
import { Fade } from '@mui/material'
import { LightTooltip } from '../../tooltips/text'
import { MultiSelectANTD } from '../../Form/multiSelet'
import { BorderIpass } from '../../BorderIpass/Index'
import { Select_Option_Resident } from '../../../types/Message_center'
import { FormType } from '../ModalCamera'

interface FormModalCameraProps {
  handleInputChange: ({ name, value }: { name: string; value: string }) => void
  brands: brand[]
  allResidents: Select_Option_Resident[]
  formValues?: FormType
}

const FormModalCamera: FC<FormModalCameraProps> = ({
  handleInputChange,
  formValues,
  brands,
  allResidents
}) => {
  return (
    <div className="input-section">
      <div className="containerInputs">
        <BorderIpass margin={1} status={'Normal'}>
          <MultiSelectANTD
            unique
            showSearch
            className="multiselect"
            colorBg="#261a3a"
            style={{ width: '100%' }}
            placeholder={
              <div>
                <img src={'images/icons/brands.png'} alt="Icono" className="select-icon" />
                SELECCIONAR MARCA
              </div>
            }
            options={brands.map(e => ({ value: e.id, label: e.name }))}
            filterOption={(input, option) =>
              typeof option?.label === 'string' &&
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            value={formValues?.brand}
            onChange={value => {
              handleInputChange({ name: 'brand', value })
            }}
            allowClear
          />
        </BorderIpass>

        <BorderIpass margin={1} status={'Normal'}>
          <MultiSelectANTD
            colorBg="#261a3a"
            showSearch
            className="multiselect"
            style={{ width: '100%' }}
            placeholder={
              <div>
                <img src={'images/icons/user.png'} alt="Icono" className="select-icon" />
                SELECCIONAR DESTINO(S)
              </div>
            }
            options={allResidents.map(option => ({
              key: option.label,
              label: (
                <LightTooltip
                  title={
                    option?.delegates?.length > 0
                      ? option?.delegates.map((delegate, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0.5rem'
                            }}
                          >
                            {delegate.enableNotification ? (
                              <Notifications style={{ color: 'green', fontSize: '1.1rem' }} />
                            ) : (
                              <NotificationsOff style={{ color: 'red', fontSize: '1.1rem' }} />
                            )}
                            {` ${delegate.name} ${delegate.lastName}`}
                            {index < option?.delegates.length - 1 && <br />}
                          </div>
                        ))
                      : 'SIN DELEGADOS'
                  }
                  followCursor
                  TransitionComponent={Fade}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    {option.enableNotification ? (
                      <Notifications style={{ color: 'green', fontSize: '1.1rem' }} />
                    ) : (
                      <NotificationsOff style={{ color: 'red', fontSize: '1.1rem' }} />
                    )}
                    {option.label}
                  </div>
                </LightTooltip>
              ),
              value: option.destinationCode
            }))}
            filterOption={(input, option) =>
              typeof option?.key === 'string' &&
              option.key.toLowerCase().includes(input.toLowerCase())
            }
            value={formValues?.destinationCode}
            onChange={value => {
              handleInputChange({ name: 'destinationCode', value })
            }}
            allowClear
            maxTagCount={4}
          />
        </BorderIpass>

        <MyTextArea
          colorBg="#261a3a"
          placeholder="Comentar (Opcional)"
          BorderSize={2}
          borderColor
          value={formValues?.comment}
          onChange={value => handleInputChange({ name: 'comment', value })}
          style={{ resize: 'none', alignSelf: 'center' }}
          rows={2}
        />
      </div>
    </div>
  )
}

export default FormModalCamera
