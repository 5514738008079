import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import './style.scss'
import { roleEnum, roleEnumString } from '../../../types/user'
import { get_whatsapp } from '../../../services/whatsapp'
import { LightTooltip } from '../../tooltips/text'
import { Fade } from '@mui/material'
import { WhatsApp } from '@mui/icons-material'
import { Navigate } from 'react-router-dom'
import { TimeToken } from './TimeToken'
import Modal from '../../GeneralComponents/Modal'
import { BorderIpass } from '../../BorderIpass/Index'

const UserInfo = () => {
  const { user, token, SocketWhats } = useAuth()
  const [numberWhatsapp, setNumberWhatsapp] = useState<string>()
  const [isVisible, setIsVisible] = useState(true)
  const [isMora, setIsMora] = useState(
    localStorage.getItem(`modal_mora`) === '1' ? true : false
  )
  const code = user ? (user.role === 'super_admin' ? user.id : user.clientId) : undefined

  useEffect(() => {
    if (!user) return
    // Función para consultar el estado de la otra aplicación
    if (user.role === 'corporate') return

    // Llamar a la función al montar el componente
    getData()

    if (SocketWhats) {
      SocketWhats.on(`Status-${code}`, () => {
        getData()
      })
    }
    // Limpia el evento cuando el componente se desmonta
  }, [user])

  useEffect(() => {
    if (!user) return

    const container = document.getElementById('main')! // Reemplaza con el ID de tu contenedor de scroll

    const handleScroll = () => {
      if (container.scrollTop > 25) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }

    // Añade el evento de scroll
    container.addEventListener('scroll', handleScroll)

    // Limpia el evento cuando el componente se desmonta
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  const getData = async () => {
    try {
      if (!code) return
      const { status } = await get_whatsapp(code, token)
      const match = status.match(/activo con (.+)/i)

      if (match) {
        setNumberWhatsapp(match[1])
      } else {
        setNumberWhatsapp(undefined)
      }
    } catch (error) {
      console.error('error al consultar estado Bot')
      setNumberWhatsapp(undefined)
    }
  }
  if (!user) return <Navigate to="/login" />

  const closeModal = () => {
    localStorage.removeItem('modal_mora')
    setIsMora(false)
  }
  return (
    <>
      <TimeToken />
      <div
        className={`${isVisible ? 'containerUserInfo visible' : ' containerUserInfo hidden'}`}
      >
        <div className="imageContainer">
          <img
            src={user.photo !== 'undefined' ? user.photo : `images/icons/user.png`}
            alt=""
          />
        </div>
        <div className="textContainer">
          <p>
            {roleEnumString[user.role as roleEnum].toLocaleUpperCase()}
            {': '}
            {user.name.toLocaleUpperCase()}
          </p>
          {user.client?.name && <p> {user.client.name.toUpperCase()}</p>}
          {user.role !== 'corporate' && (
            <LightTooltip
              title={numberWhatsapp}
              followCursor
              TransitionComponent={Fade}
              placement="right"
            >
              <div>
                <WhatsApp
                  style={{ color: numberWhatsapp ? 'green' : 'red', fontSize: '1.3rem' }}
                />
              </div>
            </LightTooltip>
          )}
        </div>
      </div>
      <Modal
        isOpen={isMora}
        borderColor={true}
        onClose={closeModal}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '520px',
          height: '250px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        style_Subcontainer={{ overflow: 'hidden', padding: '0rem 0.5rem' }}

        styleClose={{ display: 'none' }}
      >
        <div className="modalMora">
          <h2>MENSAJE DEL EQUIPO IPASS</h2>
          <p>{`Hola ${user?.name
            .toUpperCase()
            .trim()}, estamos teniendo pequeños inconvenientes para asegurar la continuidad de tu cuenta en ${user?.client?.name
            .toUpperCase()
            .trim()} derivado que no hemos podido acreditar tu(s) pago(s), por favor permítenos saber cuando hayas actuado al respecto.`}</p>
          <p>Cuando lo consideres oportuno ponte en contacto con tu ejecutivo IPASS.</p>
          <div className='buttoom' onClick={closeModal}>
            <BorderIpass margin={3}>ENTENDIDO</BorderIpass>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UserInfo
