import { IconButton } from '@mui/material'
import QrCode from 'qrcode.react'
import React, { FC, useEffect, useState } from 'react'
import QrCode2Icon from '@mui/icons-material/QrCode2'

import { PreViewQrCheckpoint } from '../../types/CheckPoints_Location'
import Button from '../Form/Button'
import Modal from '../GeneralComponents/Modal'
import { useLoading } from '../../Provider/LoadingProvider'
import { GetDataPreviewQrCheckPoint } from '../../services/checkPoint'

export const PreViewQr: FC<{
  IDLocation: string
}> = ({ IDLocation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [CheckPoint, setCheckPoint] = useState<PreViewQrCheckpoint>()
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    if (!isModalOpen) return
    const getData = async (id: string) => {
      try {
        startLoading()
        const checkPoint = await GetDataPreviewQrCheckPoint(id)
        setCheckPoint(checkPoint)
      } catch (error) {
        console.error(error)
      } finally {
        stopLoading()
      }
    }

    getData(IDLocation)
  }, [IDLocation, isModalOpen])

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  if (!CheckPoint) {
    return (
      <IconButton onClick={handleIconClick}>
        <QrCode2Icon style={{ color: 'white' }} />
      </IconButton>
    )
  }

  const qr = CheckPoint.qrCode

  const drawBackground = (
    elem: HTMLImageElement,
    item: PreViewQrCheckpoint,
    clientName: string,
    type: 'Limpieza' | 'Mantenimiento' | 'Seguridad'
  ): string => {
    const c = document.createElement('canvas')
    const ctx = c.getContext('2d') as CanvasRenderingContext2D
    const qrSize = 708 // 6 cm en píxeles a 300 DPI

    const qrMargin = 10
    const textMargin = 5

    c.width = qrSize
    c.height = qrSize

    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, c.width, c.height)

    ctx.font = 'bold 2.8rem Arial'
    ctx.fillStyle =
      type === 'Mantenimiento' ? '#008000' : type === 'Seguridad' ? '#002d80' : '#000'
    ctx.textAlign = 'center'
    const textYStart = qrMargin + textMargin + 25
    ctx.fillText(clientName || '', c.width / 2, textYStart)

    ctx.font = 'bold 2rem Arial'
    const typeY = textYStart + 1.5 * 24
    ctx.fillText(`CHECKPOINT ${item?.type.toUpperCase() || ''}`, c.width / 2, typeY)

    const qrSizeAdjusted = qrSize * 0.83
    const qrX = (c.width - qrSizeAdjusted) / 2
    const qrY = typeY + 8
    ctx.drawImage(
      elem,
      0,
      0,
      elem.width,
      elem.height,
      qrX,
      qrY,
      qrSizeAdjusted,
      qrSizeAdjusted
    )

    ctx.font = 'bold 2.2rem Arial'
    const bottomText = `${item?.name || ''} - ${item?.address || ''}`
    const bottomY = c.height - textMargin
    ctx.fillText(bottomText, c.width / 2, bottomY)

    return c.toDataURL('image/jpeg')
  }

  const download = () => {
    const canvas = document.getElementById(CheckPoint.id) as HTMLCanvasElement | null
    if (canvas) {
      const img = new Image()
      img.src = canvas.toDataURL('image/jpeg')
      img.onload = () => {
        const imageData = drawBackground(
          img,
          CheckPoint,
          CheckPoint?.clientName,
          CheckPoint.type
        )
        const downloadLink = document.createElement('a')
        downloadLink.href = imageData
        downloadLink.download = `qr_${CheckPoint?.name}_${CheckPoint?.clientName}.jpeg`
        downloadLink.click()
      }
    }
  }

  return (
    <>
      <IconButton onClick={handleIconClick}>
        <QrCode2Icon style={{ color: 'white' }} />
      </IconButton>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        styleContainer={{ width: '450px', height: '560px' }}
      >
        <div className="qr-modal">
          <h3>Código Permanente</h3>

          <div className="qr-code-containerCheckPoint">
            <h2 className={`${CheckPoint?.type}`}>{CheckPoint?.clientName || ''}</h2>
            <h3 className={`${CheckPoint?.type}`}>{CheckPoint?.type || ''}</h3>
            <div className={`${CheckPoint?.type} qr-code-wrapper`}>
              <QrCode id={CheckPoint.id} value={qr} size={250} level={'M'} />
            </div>
            <div className={`${CheckPoint?.type} qr-code-texts `}>
              <h3 className={`${CheckPoint?.type}`}>{CheckPoint?.name || ''}</h3>
            </div>
          </div>

          <div className="button-container">
            <Button text="Descargar" onClick={download} />
          </div>
        </div>
      </Modal>
    </>
  )
}
