import React, { FC, useEffect, useState } from 'react'

import './style.scss'
import { SelectOption } from '../../Form/Select'
import Button from '../../Form/Button'
import { BorderIpass } from '../../BorderIpass/Index'
import MultiSelectANTD from '../../Form/multiSelet'
import { MyTextArea } from '../../Form/labels/MyTextArea'

export interface SelectType {
  option: string
  concept: string
}

export interface LastSelectType extends SelectType {
  id: string
}

export const ConceptsRes: FC<{
  residentId: string
  title: string
  placeholderSelect: string
  placeholderText: string
  lastData?: LastSelectType
  opcions: SelectOption[]
  onSubmit: (dataSend: SelectType, residentId: string, lastData?: LastSelectType) => void
}> = ({
  residentId,
  title,
  placeholderSelect,
  placeholderText,
  lastData,
  opcions,
  onSubmit
}) => {
  const [Select, setSelect] = useState<SelectType>()

  useEffect(() => {
    setSelect(lastData)
  }, [lastData])

  return (
    <div className="containerSetCuota">
      <div className="title">
        <p>{title}</p>
      </div>

      <div className="SelectCuota">
        <BorderIpass
          margin={2}
          status={lastData ? 'Disable' : 'Normal'}
          personClassName="selectType"
        >
          <MultiSelectANTD
            disabled={lastData ? true : false}
            showSearch
            colorBg={'#00000000'}
            style={{ width: '100%' }}
            unique
            placeholder={placeholderSelect}
            options={opcions}
            value={Select ? Select?.option : undefined}
            onChange={(value: any) =>
              setSelect((prevData: any) => ({
                ...prevData,
                option: value
              }))
            }
          />
        </BorderIpass>
      </div>

      <div className="NameCuota">
        <MyTextArea
          personClassName="NameCuota"
          colorBg={'#00000000'}
          placeholder={placeholderText}
          BorderSize={2}
          borderColor
          value={Select ? Select?.concept : ''}
          onChange={value => setSelect((prevData: any) => ({ ...prevData, concept: value }))}
          style={{ resize: 'none', alignSelf: 'center' }}
          rows={1}
        />
      </div>

      <div className="ButtonCuota">
        <Button
          className="button"
          text={lastData ? 'Actualizar' : `Crear`}
          type="submit"
          onClick={() => Select && onSubmit(Select, residentId, lastData)}
        />
      </div>
    </div>
  )
}
