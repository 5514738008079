import React, { FC } from 'react'
import { Task } from './index'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import { SortableItem } from './SortableItem'
import { IconButton } from '@mui/material'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import { useAuth } from '../../Provider/AuthProvider'
import { downloadAllImages } from '../ViewQr/ZipDownloaderQR'
import { useLoading } from '../../Provider/LoadingProvider'
import { checkPointsQRs } from '../../services/checkPoint'

interface Props {
  tasks: Task[]
  list: string
  namePlan: string
  width?: string
}

export const DropZone: FC<Props> = ({ tasks, list, namePlan }) => {
  const { setNodeRef } = useDroppable({ id: list })
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  const filteredTasks = tasks.filter(task => task.list === list)

  const processDowload = async (tasks: Task[]) => {
    try {
      startLoading()
      const ids = tasks.map(task => task.id)

      const items = await checkPointsQRs(ids)

      await downloadAllImages(items, `${user?.client?.name}`, namePlan)
    } catch (error) {
    } finally {
      stopLoading()
    }
  }

  return (
    <SortableContext items={filteredTasks} strategy={rectSortingStrategy}>
      <div className="containerColumnDragAndDrop">
        <div className="ContaineheadingDragAndDrop">
          <label className="headingDragAndDrop">
            {list === 'Locaciones' ? 'Locaciones disponibles' : 'Locaciones asignadas'}
          </label>
          {list !== 'Locaciones' && (
            <IconButton onClick={() => processDowload(filteredTasks)}>
              <SystemUpdateAltIcon style={{ color: 'white' }} />
            </IconButton>
          )}
        </div>
        <div className="columnDragAndDrop" ref={setNodeRef}>
          {filteredTasks.length === 0 ? (
            <div className="emptyDropZone"></div>
          ) : (
            filteredTasks.map(task => <SortableItem key={task.id} task={task} />)
          )}
        </div>
      </div>
    </SortableContext>
  )
}
