import React, { useEffect, useRef } from 'react'

interface ClientBannerProps {
  items: any[] // Puedes especificar el tipo de los items si es necesario
  renderItem: (item: any, index: number) => React.ReactNode
  containerClassName?: string // Para permitir personalizar el contenedor
  slideClassName?: string // Para personalizar la clase de la diapositiva
  itemClassName?: string // Para personalizar la clase de cada item
}

const ClientBanner: React.FC<ClientBannerProps> = ({
  items,
  renderItem,
  containerClassName = 'client-container',
  slideClassName = 'client-slide',
  itemClassName = 'client-item'
}) => {
  const slideRef = useRef<HTMLDivElement | null>(null)

  const calculateBannerWidth = () => {
    const slide = slideRef.current
    if (slide && items.length > 0) {
      const clientItems = slide.querySelectorAll(`.${itemClassName}`)

      // Calcular el ancho total sumando cada ancho individual
      let totalWidth = 0
      clientItems.forEach(item => {
        const element = item as HTMLElement
        totalWidth += element.offsetWidth
      })

      // Obtener el ancho del contenedor
      const containerWidth = slide.parentElement?.offsetWidth || 0

      // Determinar el ancho final y la animación
      const width =
        totalWidth / 2 < containerWidth ? `${containerWidth}px` : `${totalWidth / 2}px` //se divide en dos porq el total incluye ambos lados de la transicion
      const animation = `${0.03 * parseInt(width)}s scroll infinite linear`

      // Actualizar los estilos de los elementos
      const clientSlides = slide.querySelectorAll(`.${slideClassName}`)
      clientSlides.forEach(item => {
        const element = item as HTMLElement
        element.style.animation = animation
        element.style.width = width
      })
    }
  }
  useEffect(() => {
    // Calcular el ancho al montar el componente

    calculateBannerWidth()

    window.addEventListener('resize', calculateBannerWidth)

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', calculateBannerWidth)
    }
  }, [items, itemClassName, slideClassName])

  return (
    <div className={containerClassName} ref={slideRef}>
      <div className={slideClassName}>
        {items.map((item, index) => (
          <li key={index} className={itemClassName}>
            {renderItem(item, index)}
          </li>
        ))}
      </div>

      {/* Segunda fila para el banner repetido */}
      <div className={slideClassName}>
        {items.map((item, index) => (
          <li key={index} className={itemClassName}>
            {renderItem(item, index)}
          </li>
        ))}
      </div>
    </div>
  )
}

export default ClientBanner
