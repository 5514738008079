import React, { useRef, useCallback, useState, useEffect } from 'react'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

interface FileUploadProps {
  label: string
  value?: File
  title?: string
  accept?: string
  minHeight?: string | number
  onChange: (file?: File) => void
  personClassName?: string
}

const FileUpload: React.FC<FileUploadProps> = ({
  label,
  value,
  onChange,
  accept,
  title,
  personClassName,
  minHeight = '200px'
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [fileName, setFileName] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setFileName(value instanceof File ? value.name : '')
  }, [value])

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)

    const files = e.dataTransfer.files
    if (files.length > 0) {
      onChange(files[0])
    }
  }

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) {
      onChange(files[0])
    }
    // Resetear el valor del input para permitir seleccionar el mismo archivo
    e.target.value = ''
  }

  const openFilePicker = () => {
    inputRef.current?.click()
  }

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onChange(undefined)
    },
    [onChange]
  )

  return (
    <BorderIpass margin={2} status={'Normal'} personClassName={personClassName}>
      <div
        className={`file-upload ${isDragging ? 'dragging' : ''}`}
        style={{ minHeight }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={openFilePicker}
      >
        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
          accept={accept}
        />
        <div className="file-upload-content">
          {!fileName && (
            <>
              {title && <p className="titleFile">{title}</p>}
              <p className="file-upload-placeholder">
                {isDragging ? 'Suelta aquí' : `${label}`}
              </p>
            </>
          )}
          {fileName && (
            <div className="file-upload-preview">
              <span className="file-name">{fileName}</span>
              <button
                type="button"
                className="delete-button"
                onClick={handleDeleteClick}
                aria-label="Eliminar archivo"
              >
                &times;
              </button>
            </div>
          )}
        </div>
      </div>
    </BorderIpass>
  )
}

export default FileUpload
