import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { ConfigProvider, Select } from 'antd'
import { SelectProps } from 'antd/lib'

interface MultiselectProps extends SelectProps {
  unique?: boolean
  colorBg?: string
  borderRadius?: number
}

export const MultiSelectANTD = forwardRef(
  (
    {
      unique,
      placeholder,
      onChange,
      style,
      value = [],
      colorBg = 'rgba(56, 78, 183, 0.3)',
      borderRadius = 20,
      ...props
    }: MultiselectProps,
    ref: React.Ref<{ focus: () => void }>
  ) => {
    const selectRef = useRef<any>(null)

    // Expose the focus method to the parent via the forwarded ref
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (selectRef.current) {
          selectRef.current.focus()
        }
      }
    }))

    const sharedProps: SelectProps = {
      ...(unique ? {} : { mode: 'multiple' }),
      style: { width: '100%', height: '100%' },
      maxTagCount: 'responsive',
      size: 'large'
    }

    return (
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorBgContainer: 'rgba(56, 78, 183, 0.3)',
              optionSelectedColor: 'white',
              selectorBg: colorBg,
              optionSelectedBg: 'rgba(56, 78, 183, 0.3)',
              multipleItemBg: 'rgba(56, 78, 183, 0.3)',
              colorIcon: 'white',
              colorIconHover: 'white',
              colorBorder: '#00000000',
              colorPrimary: '#00000000',
              hoverBorderColor: '#00000000',
              colorText: 'white',
              colorTextBase: 'white',
              borderRadiusOuter: borderRadius,
              borderRadius: borderRadius,
              activeBorderColor: '#00000000',
              colorBgTextHover: '#00000000',
              colorBorderBg: '#00000000',
              colorPrimaryBorderHover: '#00000000',
              colorBorderSecondary: '#00000000',
              colorInfoBorderHover: '#00000000',
              colorPrimaryBorder: '#00000000',
              colorSuccessBorderHover: '#00000000',
              colorErrorBorderHover: '#00000000',
              colorWarningBorderHover: '#00000000',
              colorInfoBorder: '#00000000',
              colorErrorBorder: '#00000000',
              colorSuccessBorder: '#00000000',
              colorWarningBorder: '#00000000'
            }
          },
          token: {
            borderRadius: borderRadius,
            borderRadiusLG: borderRadius,
            borderRadiusOuter: borderRadius,
            fontSize: 12,
            colorBgBase: 'rgba(19, 27, 66, 0.51)',
            colorBgContainer: 'rgb(165, 26, 135)',
            colorText: 'white',
            colorTextPlaceholder: 'white',
            colorIcon: '#00000000',
            colorIconHover: '#00000000',
            colorBorderBg: '#00000000',
            colorPrimaryBorderHover: '#00000000',
            colorBorderSecondary: '#00000000',
            colorInfoBorderHover: '#00000000',
            colorPrimaryActive: '#00000000',
            colorBorder: '#00000000',
            colorBgSolidHover: '#00000000',
            controlOutlineWidth: 0,
            colorSuccessBorderHover: '#00000000',
            colorErrorBorderHover: '#00000000',
            colorWarningBorderHover: '#00000000'
          }
        }}
      >
        <div className="multiSelectAntContainer" style={style}>
          <Select
            ref={selectRef}
            filterOption={(input, option) =>
              typeof option?.label === 'string' &&
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            {...sharedProps}
            {...props}
          />
        </div>
      </ConfigProvider>
    )
  }
)

export default MultiSelectANTD
