import QRCodeIcon from '@mui/icons-material/QrCode'
import { IconButton } from '@mui/material'
import QrCode from 'qrcode.react'
import React, { FC, useState } from 'react'
import { DelegateResident } from '../../types/delegate_resident'
import { Resident } from '../../types/resident'
import { Worker } from '../../types/worker'
import { CheckPointsLocation } from '../../types/CheckPoints_Location'
import Button from '../Form/Button'
import Modal from '../GeneralComponents/Modal'
import PopUpConfirm from '../Table/components/PopupConfirm'
import './style.scss'

const ViewQr: FC<{
  item: Resident | DelegateResident | Worker | CheckPointsLocation
  onSubmit: () => void
  isCheckPoints?: boolean
  regenerateCode: (id: string) => Promise<{ qr: string }>
  text?: {
    clientName: string
    destine: string
    names: string
    name: string
    lastName: string
    type: string
    typeCheckPoint?: 'Limpieza' | 'Mantenimiento' | 'Seguridad'
  }
}> = ({ item, onSubmit, regenerateCode, text, isCheckPoints }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [qr, setQr] = useState(item.qrCode)
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const handleIconClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    onSubmit()
  }

  const drawBackground = (elem: HTMLImageElement) => {
    const c = document.createElement('canvas')
    const ctx = c.getContext('2d') as CanvasRenderingContext2D
    const qrSize = 708 // 6 cm en píxeles a 300 DPI

    const qrMargin = 10
    const textMargin = 5
    const bottomSpace = 150

    if (isCheckPoints) {
      // Tamaño del canvas para CheckPointsLocation
      c.width = qrSize
      c.height = qrSize // Asegúrate de que la altura del canvas sea la misma que el ancho

      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, c.width, c.height)

      // Dibujar clientName en la parte superior con fuente más grande
      ctx.font = 'bold 2.8rem Arial' // Fuente más grande para clientName
      ctx.fillStyle =
        text?.type === 'mantenimiento'
          ? '#008000'
          : text?.type === 'Seguridad'
          ? '#002d80'
          : '#000'
      ctx.textAlign = 'center'
      const textYStart = qrMargin + textMargin + 25 // Ajusta el espacio según sea necesario
      ctx.fillText(text?.clientName || '', c.width / 2, textYStart)

      // Dibujar type debajo de clientName
      ctx.font = 'bold 2rem Arial' // Fuente normal para type
      const typeY = textYStart + 1.5 * 24 // Ajusta el espacio según el tamaño de la fuente
      ctx.fillText(text?.type || '', c.width / 2, typeY)

      // Ajustar tamaño del QR a 85% del tamaño del canvas y centrarlo
      const qrSizeAdjusted = qrSize * 0.83
      const qrX = (c.width - qrSizeAdjusted) / 2 // Centrando horizontalmente
      const qrY = typeY + 8 // Posición Y para el QR
      ctx.drawImage(
        elem,
        0,
        0,
        elem.width,
        elem.height,
        qrX,
        qrY,
        qrSizeAdjusted,
        qrSizeAdjusted
      )

      // Dibujar names y destine en la parte inferior
      ctx.font = 'bold 2.2rem Arial' // Fuente normal para bottomText
      const bottomText = `${text?.names || ''} - ${text?.destine || ''}`
      const bottomY = c.height - textMargin
      ctx.fillText(bottomText, c.width / 2, bottomY)
    } else {
      c.height = elem.height + qrMargin + 3 * textMargin + bottomSpace - 40
      c.width = elem.width + 2 * qrMargin
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, c.width, c.height)

      // Dibujar los demás textos debajo de la imagen
      ctx.font = 'bold 1.4rem Arial' // Negrita
      ctx.fillStyle = '#000'
      ctx.textAlign = 'center'

      let textY = elem.height + qrMargin + textMargin + 4 * 13 + textMargin

      ctx.fillText(text?.name || '', c.width / 2, textY)
      textY += 10 * 2 + textMargin
      ctx.fillText(text?.lastName || '', c.width / 2, textY)
      textY += 2 * 10 + textMargin

      ctx.font = 'bold 1.4rem Arial' // Negrita

      ctx.fillText(` ${text?.destine || ''} - ${text?.type || ''} `, c.width / 2, textY)

      // Dibujar el clientName encima de la imagen
      ctx.font = 'bold 1.5rem Arial' // Negrita
      ctx.fillStyle = '#000'
      ctx.textAlign = 'center'
      textY = qrMargin + textMargin + 16
      ctx.fillText(text?.clientName || '', c.width / 2, textY)

      // Dibujar el código QR debajo del texto
      ctx.drawImage(
        elem,
        0,
        0,
        elem.width,
        elem.height,
        qrMargin,
        qrMargin + textY + textMargin, // Posición Y debajo del texto
        elem.width,
        elem.height
      )
    }

    return c.toDataURL('image/jpeg')
  }

  const download = () => {
    const canvas = document.getElementById(item.id) as HTMLCanvasElement | null
    if (canvas) {
      const img = new Image()
      img.src = canvas.toDataURL('image/jpeg')
      img.onload = () => {
        const imageData = drawBackground(img)
        const downloadLink = document.createElement('a')
        downloadLink.href = imageData
        downloadLink.download = `qr_${text?.names}_${text?.clientName}_${text?.destine}.jpeg`
        downloadLink.click()
      }
    }
  }

  const handleRegenerate = async () => {
    try {
      const { qr } = await regenerateCode(item.id)
      setQr(qr)
      setIsPopUpOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }
  const handleConfirm = () => {
    setIsPopUpOpen(true)
  }
  return (
    <>
      <IconButton onClick={handleIconClick}>
        <QRCodeIcon style={{ color: 'white' }} />
      </IconButton>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="qr-modal">
          <h3>Código Permanente</h3>
          <>
            {!isCheckPoints && (
              <div className="qr-code-container">
                <h3>{text?.clientName || ''}</h3>
                <div className="qr-code-wrapper">
                  <QrCode id={item.id} value={qr} size={300} level={'M'} />
                </div>
                <div className="qr-code-texts">
                  <h3>{text?.destine || ''}</h3>
                  <h3>{text?.names || ''}</h3>
                  <h3>{text?.type || ''}</h3>
                </div>
              </div>
            )}

            {isCheckPoints && (
              <div className="qr-code-containerCheckPoint">
                <h2 className={`${text?.type}`}>{text?.clientName || ''}</h2>
                <h3 className={`${text?.type}`}>{text?.type || ''}</h3>
                <div className={`${text?.type} qr-code-wrapper`}>
                  <QrCode id={item.id} value={qr} size={300} level={'M'} />
                </div>
                <div className={`${text?.type} qr-code-texts `}>
                  <h3 className={`${text?.type}`}>
                    {text?.names || ''} - {text?.destine || ''}
                  </h3>
                </div>
              </div>
            )}
          </>
          <div className="button-container">
            <Button text="Descargar" onClick={download} />
            <Button text="Regenerar" onClick={handleConfirm} />
          </div>
        </div>
      </Modal>
      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title="¿Estás seguro de regenerar?"
        description="Esta acción no se puede deshacer."
        acceptText="Aceptar"
        rejectText="Cancelar"
        handleAccept={handleRegenerate}
        handleReject={handleReject}
      />
    </>
  )
}

export default ViewQr
