import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Image } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { IconButton } from '@mui/material'

import {
  Update,
  CheckCircle,
  Cancel,
  DisabledByDefaultOutlined,
  IndeterminateCheckBoxOutlined,
  Edit,
  CheckBoxOutlined
} from '@mui/icons-material'
import { getPaymentDetail } from '../../../services/Payments'
import './style.scss'
import { listPaymentsResident, PaymentDetaild, PaymentDetails } from '../../../types/Payments'
import Modal from '../../GeneralComponents/Modal'
import { ModalEditPayment } from './ModalEditPayment'

const PaymentsDetails: FC<{ Predata: listPaymentsResident; onClose: () => void }> = ({
  Predata,
  onClose
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [data, setData] = useState<PaymentDetails>()
  const [select, setSelect] = useState<PaymentDetaild>()
  const [photo, setPhoto] = useState('images/logo_inicio.png')
  const altPhoto = 'images/logo_inicio.png'

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      startLoading()
      const statement = await getPaymentDetail({
        residentId: Predata.residentId,
        month: Predata.month
      })
      setData(statement)
      statement?.lastPhoto && setPhoto(statement.lastPhoto)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const statusSvg: {
    [key: string]: { generalStatus: React.JSX.Element; detailsStatus: React.JSX.Element }
  } = {
    Payment: {
      generalStatus: <CheckCircle style={{ color: 'green' }} />,
      detailsStatus: <CheckBoxOutlined style={{ color: 'green' }} />
    },

    Process: {
      generalStatus: <Update style={{ color: 'yellow' }} />,
      detailsStatus: <IndeterminateCheckBoxOutlined style={{ color: 'antiquewhite' }} />
    },

    Reject: {
      generalStatus: <Cancel style={{ color: 'red' }} />,
      detailsStatus: <DisabledByDefaultOutlined style={{ color: 'red' }} />
    }
  }

  const handlePhoto = (photo: string) => {
    setPhoto(photo)
  }

  const handleClosed = () => {
    setSelect(undefined)
    getData()
  }

  return (
    <div className="paymentsDetail-container">
      {data && (
        <>
          <div className="containerphoto">
            <div className="photo">
              <Image
                preview={{
                  mask: (
                    <div>
                      <EyeOutlined />
                      <span>Ver</span>
                    </div>
                  )
                }}
                wrapperStyle={{ width: '100%', height: '100%' }}
                style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0px' }}
                src={photo}
                className="image"
              />
            </div>
          </div>

          <div className="inf">
            <div className="database-container">
              <div className="data">
                <p>ACREDITÓ {data.dateAcredit || '--'}</p>
                <p>{Predata.names}</p>

                <p>{data.clienName}</p>

                <p>ESTADO DE CUENTA - {data.invoiceDate}</p>
              </div>
              <div className="status">{statusSvg[Predata.status]?.generalStatus}</div>
            </div>

            <div className="statement">
              {data.details.map((detail, index) => (
                <div
                  key={index}
                  className="detail"
                  style={{ gridTemplateColumns: '1fr 2fr 2fr 4fr 3fr 2fr 0.5fr' }}
                >
                  <img
                    onClick={() =>
                      detail.type === 'Pago' && detail?.photo && handlePhoto(detail?.photo)
                    }
                    src={detail.photo || altPhoto}
                    alt={'foto o icono'}
                    style={{
                      width: '30px',
                      height: '30px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                      cursor: detail.type === 'Pago' ? 'pointer' : 'default'
                    }}
                  />{' '}
                  <span>{detail.originalDate}</span>
                  <span>{detail.type}</span>
                  <span>{detail.name}</span>
                  <span>{detail.conceptDate}</span>
                  <span
                    style={
                      detail.isDelete && detail.id !== '0'
                        ? {
                            textDecoration: 'line-through',
                            textDecorationColor: '#ff0000',
                            textDecorationThickness: '1px'
                          }
                        : {}
                    }
                  >
                    Q {detail.amount.toFixed(2)}
                  </span>
                  {detail.type === 'Pago' && !detail.dateConfirm ? (
                    <IconButton onClick={() => setSelect(detail)}>
                      <Edit style={{ color: 'antiquewhite' }} />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
            <div className="totalStatement">
              <div>SALDO A LA FECHA</div>
              <div>
                <span>Q {data.balance.toFixed(2)}</span>
              </div>
            </div>
          </div>

          {select && (
            <Modal
              isOpen={true}
              borderColor
              onClose={handleClosed}
              styleContainerContent={{ padding: '5px', margin: '0px' }}
              styleContainer={{
                width: '50%',
                height: '40%',
                padding: '0px',
                overflow: 'hidden',
                margin: '5px',
                borderRadius: '20px'
              }}
              styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
            >
              <ModalEditPayment
                IsEdit={select?.dateConfirm ? true : false}
                select={select}
                onClose={handleClosed}
              />
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default PaymentsDetails
