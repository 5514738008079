import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  faBuilding,
  faDoorOpen,
  faUser,
  faQrcode,
  faIndustry,
  faBars,
  faGlobeAmericas,
  faFileInvoice,
  faClipboardCheck,
  faCalendarDays,
  faClipboardUser,
  faPersonMilitaryPointing,
  faUserTie,
  faChartLine,
  faWallet,
  faBriefcase,
  faTimeline,
  faLocationCrosshairs,
  faRoute,
  faClapperboard,
  faFileImage,
  faMessage,
  faScaleUnbalanced
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { useAuth } from '../../../Provider/AuthProvider'
import { roleEnum } from '../../../types/user'
import { Route } from '../../../types/routes'

import './styles.scss'
import { useLoading } from '../../../Provider/LoadingProvider'
import { navBarStore } from '../../../services/store'
import { io } from 'socket.io-client'

const Sidebar = () => {
  const { statusBar, setStatus } = navBarStore()
  const { logout, user } = useAuth()
  const { id } = useParams<{ id: string }>()
  const { isElectron } = useLoading()
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null)
  const [SelectCorp, setSelectCorp] = useState<{
    [key: string]: string
  } | null>(null)
  const [idSearch, setIdSearch] = useState<string | undefined>(undefined)
  const TicketSound = useRef<HTMLAudioElement>(null)
  const emergencySound = useRef<HTMLAudioElement>(null)
  const [userInteracted, setUserInteracted] = useState(false)

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)
  }, [id, idSearch, setIdSearch, user])

  useEffect(() => {
    // Detectar primera interacción del usuario
    const handleUserInteraction = () => {
      setUserInteracted(true)
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('keydown', handleUserInteraction)
    }

    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('keydown', handleUserInteraction)

    const socket = io(process.env.REACT_APP_API_URL_WHATS || 'https://whats.ipass.com.gt')

    if (idSearch && userInteracted) {
      socket.on(`ReportTicket-${idSearch}`, () => {
        TicketSound.current?.play()
      })

      socket.on(`ReportEmergency-${idSearch}`, () => {
        emergencySound.current?.play()
      })
    }

    return () => {
      socket.disconnect()
    }
  }, [idSearch, userInteracted])

  useEffect(() => {
    if (user && user?.clientSelect) {
      const select = JSON.parse(user?.clientSelect)
      setSelectCorp(select)
      console.log(select)
    }
  }, [])

  const toggleSubMenu = (index: number) => {
    setOpenSubMenu(openSubMenu === index ? null : index)
  }

  const routes: Record<string, Route[]> = {
    [roleEnum.SUPER_ADMIN]: [
      { to: '/clients', icon: faUser, label: 'Clientes' },
      { to: '/super_admin', icon: faUserTie, label: 'Super admins' },
      { to: '/destination', icon: faBuilding, label: 'Destinos' },
      { to: '/brands', icon: faIndustry, label: 'Marcas' },
      { to: '/origins', icon: faGlobeAmericas, label: 'Orígenes' },
      { to: '/corps', icon: faBriefcase, label: 'Corporativos' },
      { to: '/whatsapp', icon: faQrcode, label: 'WhatsApp' }
    ],
    [roleEnum.CORP]: [
      { to: '/dashboard_corp', icon: faClipboardCheck, label: 'Dashboard' },
      {
        to: '#',
        icon: faUser,
        label: 'Afiliados',
        subRoutes: [{ to: '/dashboard_corp/', icon: faTimeline, label: 'Usuarios' }]
      }
    ],
    [roleEnum.ADMIN]: [
      {
        to: '#',
        icon: faClipboardCheck,
        label: 'Check in',
        subRoutes: [
          { to: '/check_in', icon: faClipboardCheck, label: 'Check in' },
          { to: '/access', icon: faFileImage, label: 'Accesos' },
          { to: '/receptionist', icon: faPersonMilitaryPointing, label: 'Recepcionistas' },
          { to: '/report', icon: faFileInvoice, label: 'Reportes' }
        ]
      },
      { to: '/staff', icon: faClipboardUser, label: 'Trabajadores' },

      { to: '/resident', icon: faUser, label: 'Residentes' },
      { to: '/whatsapp', icon: faQrcode, label: 'WhatsApp' },
      { to: '/message_center', icon: faMessage, label: 'Mensajes' },
      {
        to: '#',
        icon: faBriefcase,
        label: 'CheckPoints',
        subRoutes: [
          {
            to: '/check_points/dashboard',
            icon: faChartLine,
            label: 'Dashboard'
          },

          {
            to: '/check_points/locations',
            icon: faLocationCrosshairs,
            label: 'Locaciones'
          },
          { to: '/check_points/routines', icon: faRoute, label: 'Planes' }
        ]
      },
      { to: '/dashboard', icon: faChartLine, label: 'Dashboard' },
      {
        to: '#',
        icon: faClipboardCheck,
        label: 'Acreditamiento',
        subRoutes: [
          { to: '/accreditations/concepts', icon: faFileImage, label: 'Conceptos' },
          { to: '/accreditations/dates', icon: faCalendarDays, label: 'Fechas' },
          { to: '/accreditations/penalty', icon: faScaleUnbalanced, label: 'Politica de Mora' },
          { to: '/accreditations/dashboard', icon: faWallet, label: 'Dashboard' }
        ]
      }
    ],
    [roleEnum.RECEPTION]: isElectron
      ? [
          { to: '/check_in', icon: faClipboardCheck, label: 'Check-In' },
          { to: '/report', icon: faFileInvoice, label: 'Reportes' }
        ]
      : [
          { to: '/check_in', icon: faClipboardCheck, label: 'Check-In' },
          { to: '/check_access', icon: faClapperboard, label: 'Check-In Central' },
          { to: '/report', icon: faFileInvoice, label: 'Reportes' }
        ]
  }

  if (!user || !user.role) {
    return null
  }

  return (
    <div className={`sidebar ${statusBar ? 'open' : ''}`}>
      <div className="header">
        <img
          src={isElectron ? 'images/logo_inicio.png' : '/images/logo_inicio.png'}
          style={{ display: statusBar ? 'none' : 'initial' }}
          alt="Logo"
          className="logo"
        />
        <FontAwesomeIcon
          style={{ color: 'white' }}
          icon={faBars}
          className={`burger ${statusBar && 'hamburguer'}`}
          onClick={setStatus}
        />
      </div>
      <div className={`menuItems ${statusBar && 'hamburguer'}`}>
        {routes[user.role].map((route, index) => (
          <Link
            key={index}
            className="menuItem"
            to={route.to}
            title={route.label}
            onClick={() => route.subRoutes && toggleSubMenu(index)}
          >
            <FontAwesomeIcon icon={route.icon} className="icon" />
            <span style={{ display: statusBar ? 'none' : 'initial' }}>{route.label}</span>

            {route.subRoutes &&
              !statusBar &&
              openSubMenu === index &&
              user?.role !== `corporate` && (
                <div className="submenu">
                  {route.subRoutes.map((subRoute, subindex) => (
                    <Link
                      key={subindex}
                      to={subRoute.to}
                      className="submenuItem"
                      title={subRoute.label}
                    >
                      <FontAwesomeIcon icon={subRoute.icon} className="icon" />
                      <span style={{ display: statusBar ? 'none' : 'initial' }}>
                        {subRoute.label}
                      </span>
                    </Link>
                  ))}
                </div>
              )}

            {SelectCorp &&
              !statusBar &&
              user?.role === `corporate` &&
              openSubMenu === index && (
                <div className="submenu">
                  {Object.entries(SelectCorp).map(([id, name]) => (
                    <Link
                      key={id}
                      to={`dashboard_corp/${id}`}
                      className="submenuItem"
                      title={name}
                    >
                      <FontAwesomeIcon icon={faUser} className="icon" />
                      <span style={{ display: statusBar ? 'none' : 'initial' }}>{name}</span>
                    </Link>
                  ))}
                </div>
              )}
          </Link>
        ))}
      </div>
      <div className="footer" onClick={logout}>
        <FontAwesomeIcon icon={faDoorOpen} className="icon" />
        <span style={{ display: statusBar ? 'none' : 'initial' }}>Cerrar Sesión</span>
      </div>

      <audio ref={TicketSound} src="sounds/Ticket_resident.mp3" />
      <audio ref={emergencySound} src="sounds/Ticket_emergency.mp3" />
    </div>
  )
}

export default Sidebar
