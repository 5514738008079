import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'
import Button from '../../components/Form/Button'
import FileUpload from '../../components/Form/FileUpload'
import Modal from '../../components/GeneralComponents/Modal'
import Table, { enumTypeColumn, TableColumn } from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import { columnsResident } from '../../components/Tables/Resident/Columns'
import { createResidents } from '../../services/resident'
import { ResidentCreateExcel } from '../../types/resident'
import { Pagination } from '../../types/types'
import { LightTooltip } from '../../components/tooltips/text'
import { Fade } from '@mui/material'

const UploadExcel: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [valueFile, setValueFile] = useState<File>()
  const [data, setData] = useState<ResidentCreateExcel[]>([])
  const [pagination, setPagination] = useState<Pagination<ResidentCreateExcel>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 0,
    totalPages: 1,
    data: []
  })
  const { startLoading, stopLoading, showMessage } = useLoading()
  const onCloseModal = () => {
    setIsOpen(false)
  }

  const Actions = ({ id }: { id: string; item: ResidentCreateExcel }) => {
    return (
      <div style={{ display: 'flex' }}>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  const parsePhoneNumber = (phoneNumber: string | undefined): number => {
    const countryCode = '502'
    if (!phoneNumber) {
      return NaN
    }
    const formattedPhoneNumber = phoneNumber.startsWith(countryCode)
      ? phoneNumber
      : `${countryCode}${phoneNumber}`

    return parseInt(formattedPhoneNumber)
  }

  const handleChangeFile = async (file?: File) => {
    if (!file) {
      setValueFile(file)
      setData([])
      return
    }

    setValueFile(file)
    const rows = await readXlsxFile(file)

    if (rows[0][0] !== 'TIPO DE DESTINO') {
      message.error('No coincide el formato')
      return
    }

    const result: ResidentCreateExcel[] = []

    if (rows.length >= 0) {
      for (let k = 1; k < rows.length; k++) {
        const row = rows[k]

        const resident: ResidentCreateExcel = {
          id: (k - 1).toString(),
          destinationName: row[0] as string,
          destinationCode: (row[1] as string).toString(),
          name: row[2] as string,
          lastName: row[3] as string,
          phone1: row[4] ? parsePhoneNumber(row[4].toString()) : undefined,
          phone2: row[5] ? parsePhoneNumber(row[5].toString()) : undefined,
          email: row[6] as string,
          clientId: user?.clientId as string,
          delegatedQuantity: parseInt(row[7] as string),
          delegateResidents: [] // Aquí almacenaremos los residentes delegados
        }

        // Procesar columnas a partir de la 8 (índice 7)
        for (let col = 8; col < row.length; col += 3) {
          if (row[col] || row[col + 1] || row[col + 2]) {
            const delegatedResident = {
              name: (row[col] as string) || '',
              lastName: (row[col + 1] as string) || '',
              phone: row[col + 2] ? parsePhoneNumber(row[col + 2].toString()) : undefined
            }
            resident.delegateResidents.push(delegatedResident)
          } else {
            // Si no hay datos en las columnas, dejamos de buscar
            break
          }
        }

        result.push(resident)
      }
    }

    setData(result)
  }

  const calculatePagination = (
    data: ResidentCreateExcel[]
  ): Pagination<ResidentCreateExcel> => {
    const totalElements = data.length
    const elementsPerPage = 6
    const totalPages = Math.ceil(totalElements / elementsPerPage)

    return {
      currentPage: 1,
      hasNextPage: totalPages > 1,
      hasPrevPage: false,
      total: totalElements,
      totalPages: totalPages,
      data: data.slice(0, elementsPerPage)
    }
  }
  const handleDelete = (id: string) => {
    const newData = pagination.data.filter(resident => resident.id !== id)
    const newPagination = calculatePagination(newData)
    setPagination(newPagination)
  }

  useEffect(() => {
    setPagination(calculatePagination(data))
  }, [data])

  const handlePageChange = (page: number) => {
    const startIndex = (page - 1) * 6
    const endIndex = startIndex + 6
    setPagination({
      ...pagination,
      currentPage: page,
      hasNextPage: page < pagination.totalPages,
      hasPrevPage: page > 1,
      data: data.slice(startIndex, endIndex)
    })
  }
  const handleCreateResidents = async () => {
    try {
      startLoading()
      await sendMultiple(data)
      showMessage('success', 'Creados con éxito')
      onClose()
    } catch (error) {
      showMessage('error', (error as any)?.response?.data?.message)
    } finally {
      stopLoading()
    }
  }

  async function sendMultiple(residents: ResidentCreateExcel[]): Promise<void> {
    const batchSize = 10
    const chunks = residents.reduce<ResidentCreateExcel[][]>((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / batchSize)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    const promises = chunks.map(async chunk => {
      try {
        await createResidents({ residents: chunk })
      } catch (error) {
        console.error('Error al enviar chunk:', error)
      }
    })

    try {
      await Promise.all(promises)
      console.info('Todos los residentes han sido enviados')
    } catch (error) {
      console.error('Error al enviar residentes:', error)
    }
  }

  const columnsResidentEnable: TableColumn[] = [
    ...columnsResident,
    {
      title: 'Delegados',
      dataIndex: 'delegatedQuantity',
      key: 'delegatedQuantity',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { item?: ResidentCreateExcel }) => {
        if (!item) return null
        return (
          <LightTooltip
            title={
              item.delegateResidents?.length > 0
                ? item.delegateResidents.map((delegate, index) => (
                    <span key={index}>
                      {` ${delegate.name} ${delegate.lastName}`}
                      {index < item.delegateResidents?.length - 1 && <br />}
                    </span>
                  ))
                : 'SIN DELEGADOS'
            }
            followCursor
            TransitionComponent={Fade}
          >
            <div className="containerDelegatesQuantity">
              <div>{`${item?.delegatedQuantity || 0}/${
                item?.delegateResidents?.length || 0
              }`}</div>
            </div>
          </LightTooltip>
        )
      }
    }
  ]

  return (
    <>
      <Button onClick={() => setIsOpen(true)} icon={<CloudUploadOutlinedIcon />} />
      <Modal onClose={onCloseModal} isOpen={isOpen}>
        <div className="title">
          <h2>{`Nuevos residentes`}</h2>

          <FileUpload
            title="Planilla de nuevos residentes"
            label={'Elecciona ó arrastra la planilla excel'}
            value={valueFile}
            onChange={handleChangeFile}
          />

          <Table
            onPageChange={handlePageChange}
            pagination={pagination}
            canAdd={false}
            columns={columnsResidentEnable}
            Actions={Actions as any}
            itemsPerPage={6}
          />
          <Button onClick={handleCreateResidents} text="Crear" />
        </div>
      </Modal>
    </>
  )
}

export default UploadExcel
