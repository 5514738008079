import { PaymentDateType } from '../types/client'
import axiosInstance from './axiosInstance'

const headers = {
  'Content-Type': 'application/json'
}

export const savePaymentDate = async (params: PaymentDateType): Promise<boolean> => {
  try {
    const response = await axiosInstance.post(
      `/api/manageress/savePaymentDate`,
      { paymentDate: params },
      {
        headers
      }
    )
    return response.status === 200
  } catch (error) {
    console.error('Error al guardar PaymentDate:', error)
    return false
  }
}

export const savePenaltyDate = async (configSave: {
  fee: 'fixed' | 'percentage'
  timeSelect: 'diary' | 'monthly'
  value: string
}): Promise<boolean> => {
  try {
    const response = await axiosInstance.post(
      `/api/manageress/savePenaltyDate`,
      { config: configSave },
      {
        headers
      }
    )
    return response.status === 200
  } catch (error) {
    console.error('Error al guardar PaymentDate:', error)
    return false
  }
}

export const getPenaltyDate = async (): Promise<
  | {
      fee: 'fixed' | 'percentage'
      timeSelect: 'diary' | 'monthly'
      value: string | number
    }
  | undefined
> => {
  try {
    const response = await axiosInstance.get(`/api/manageress/PenaltyDate`)
    return response.data
  } catch (error) {
    console.error('Error al guardar PaymentDate:', error)
    return
  }
}
