import React, { ChangeEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import XLSX from 'xlsx-js-style'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { GeneralData, searchReport } from '../../types/dashboard'
import ReactECharts from 'echarts-for-react'
import Modal from '../../components/GeneralComponents/Modal'
import ReportModal from '../../components/modalDashboard/seeMore'
import ModifyStateModal from '../../components/modalDashboard/ModifyStateModal'
import AssignWorkerModal from '../../components/modalDashboard/AssignWorkerModal'
import { SelectChangeEvent } from '@mui/material/Select'

import moment from 'moment'

import {
  Search,
  ManageHistory,
  WorkHistory,
  HowToReg,
  ExpandLess,
  ExpandMore,
  GridOn,
  ExitToAppOutlined
} from '@mui/icons-material/'
import { IconButton, Fade } from '@mui/material'
import { getGeneralDataByClientId, getReportForDate } from '../../services/dashboard'
import { useLoading } from '../../Provider/LoadingProvider'
import Select from '../../components/Form/select2'
import MultiSelect from '../../components/Form/selectFromMultiselect'
import DateRangePicker from '../../components/Form/RangePicker2'
import { LightTooltip } from '../../components/tooltips/text'
import PhotoTooltip from '../../components/tooltips/img'

import {
  faTriangleExclamation,
  faCircleCheck,
  faCircle,
  faCircleNotch,
  faCircleArrowDown,
  faCircleArrowUp,
  faHandPointUp,
  faBuilding,
  faTicket
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientBanner from '../../components/GeneralComponents/SliderBanner'
import { barChartOptions, pieChartOptions } from './Chars'

const Dashboard = () => {
  const { user, token, SocketWhats } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const [GeneralData, setGeneralData] = useState<GeneralData>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [oldForData, setOldForData] = useState(['', ''])
  const [slider, setSlider] = useState(true)
  const [idSearch, setIdSearch] = useState<string | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTime, setCurrentTime] = useState(moment())

  const [selected, setSelected] = useState<{ id: string; action: string } | undefined>(
    undefined
  )

  const [search, setSearch] = useState({
    status: ['Open', 'Hold'],
    priority: '',
    staff: '',
    order: 'asc',
    description: '',
    dateSelect: ['', '']
  })

  const [filteredReports, setFilteredReports] = useState<searchReport[]>([])

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)
  }, [id, idSearch, setIdSearch, user])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment())
    }, 60000) // 60000ms = 1 minuto

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const eventAction = (message: string) => {
      getGeneralData(message)
    }
    const ActionUpdate = (data: searchReport) => {
      setGeneralData(lastData =>
        lastData
          ? {
              ...lastData,
              Reports: lastData.Reports.map(report =>
                report.id === data.id ? { ...report, status: data.status } : report
              )
            }
          : undefined
      )
    }

    if (SocketWhats && idSearch) {
      getGeneralData(idSearch)
      SocketWhats.on(`ReportTicket-${idSearch}`, eventAction)
      SocketWhats.on(`ReportEmergency-${idSearch}`, eventAction)
      SocketWhats.on(`Report-${idSearch}`, eventAction)
      SocketWhats.on(`Update_Report_status-${idSearch}`, ActionUpdate)
    }

    return () => {
      SocketWhats && SocketWhats.off(`ReportTicket-${idSearch}`, eventAction)
      SocketWhats && SocketWhats.off(`ReportEmergency-${idSearch}`, eventAction)
      SocketWhats && SocketWhats.off(`Report-${idSearch}`, eventAction)
      SocketWhats && SocketWhats.off(`Update_Report_status-${idSearch}`, ActionUpdate)
    }
  }, [idSearch, SocketWhats])

  useEffect(() => {
    if (search.dateSelect[0] !== oldForData[0] || search.dateSelect[1] !== oldForData[1])
      SetReportDate([search.dateSelect[0], search.dateSelect[1]])
    const searchTerms = search.description.trim().toLowerCase().split(/\s+/)

    // Función para filtrar los reportes
    const filterReports = (report: searchReport) => {
      const matchesDescriptionOrShortId = searchTerms.every(
        term =>
          report.description?.toLowerCase().includes(term) ||
          report.shortID.toLowerCase().includes(term)
      )

      // Filtrar por múltiples estatus
      const matchesStatus = search.status.length === 0 || search.status.includes(report.status)

      const matchesStaff = search.staff === '' || report.resolvedById === search.staff
      const matchesPriority = search.priority === '' || report.priority === search.priority

      return matchesDescriptionOrShortId && matchesStatus && matchesStaff && matchesPriority
    }

    // Filtrar los reportes
    const filtered = GeneralData?.Reports && GeneralData.Reports.filter(filterReports)

    // Función para ordenar los reportes
    const sortReports = (
      a: { createdAt: string | number | Date },
      b: { createdAt: string | number | Date }
    ) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return search.order === 'desc' ? dateA - dateB : dateB - dateA
    }

    // Ordenar los reportes filtrados
    const sorted = filtered && filtered.sort(sortReports)

    // Actualizar los reportes filtrados y ordenados
    sorted && setFilteredReports(sorted)
  }, [GeneralData, search])

  const getGeneralData = async (id: string): Promise<void> => {
    try {
      startLoading()
      if (search.dateSelect[0] && search.dateSelect[1]) {
        await SetReportDate([search.dateSelect[0], search.dateSelect[1]])
        return
      } else {
        const data = await getGeneralDataByClientId(id)
        setGeneralData(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const SetReportDate = async (date: [string, string]) => {
    try {
      setOldForData(date)
      if (!GeneralData || !idSearch) return
      if (!date[0] || !date[1]) {
        return getGeneralData(idSearch)
      } else {
        startLoading()
        const newReports = await getReportForDate(date, idSearch, token)
        setGeneralData({ ...GeneralData, Reports: newReports })
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const options = [
    { value: 'Open', label: 'Abierto' },
    { value: 'Close', label: 'Cerrado' },
    { value: 'Hold', label: 'Espera' },
    { value: 'Evaluating', label: 'Evaluando' },
    { value: 'Spam', label: 'Irrelevante' }
  ]

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    Open: <FontAwesomeIcon icon={faCircleNotch} className="dashBoardOpen" />,
    Close: <FontAwesomeIcon icon={faCircleCheck} className="dashBoardClose" />,
    Hold: <FontAwesomeIcon icon={faCircle} className="dashBoardHold" />,
    Evaluating: <FontAwesomeIcon icon={faCircle} className="dashBoardEvaluating" />,
    Spam: <FontAwesomeIcon icon={faCircle} className="dashBoardSpam" />
  }

  const priority: {
    [key: string]: string
  } = {
    Emergency: 'Emergencia',
    Report: 'reporte',
    Express: 'Ticket Express',
    CheckPoint: 'CheckPoint'
  }

  if (!GeneralData)
    return (
      <div className="DashboardContainer">
        <div className="title_Dashboard">
          <h4>Dashboard</h4>
        </div>
      </div>
    )

  const getTimeDifference = (createdAt: string) => {
    const currentTime = moment()
    const createdTime = moment(createdAt)
    const diff = currentTime.diff(createdTime)
    const diffDuration = moment.duration(diff)

    if (diffDuration.asDays() >= 1) {
      return `${Math.floor(diffDuration.asDays())} días`
    } else if (diffDuration.asHours() >= 1) {
      return `${Math.floor(diffDuration.asHours())} horas`
    } else {
      const minutes = Math.floor(diffDuration.asMinutes())
      return `${minutes >= 0 ? minutes : 0} minutos`
    }
  }

  const handleChange = (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | SelectChangeEvent<string[]>
  ) => {
    const { name, value } = e.target

    if (name === 'description') {
      const delayDebounceFn = setTimeout(() => {
        setSearch(prevSearch => ({
          ...prevSearch,
          [name]: Array.isArray(value) ? value.join(' ') : value // Convierte a string si es un array
        }))
      }, 900)

      return () => clearTimeout(delayDebounceFn)
    } else {
      setSearch(prevSearch => ({
        ...prevSearch,
        [name]: value // Asegúrate de que sea un string
      }))
    }
  }
  const handleView = (id: string, action: string) => {
    setSelected({ id, action })
    setIsModalOpen(true)
  }

  const closeModal = async () => {
    startLoading()
    try {
      setIsModalOpen(false)
      setSelected(undefined)
      if (idSearch) {
        await getGeneralData(idSearch)
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const Actions: React.FC<{
    id: string
    state: string
  }> = ({ id, state }) => {
    const isDisabled = state === 'Close'

    const disabledButtonStyles = {
      color: 'gray',
      pointerEvents: 'none'
    }

    const enabledButtonStyles = {
      color: 'white'
    }

    return (
      <>
        <LightTooltip title="Modificar estado" TransitionComponent={Fade}>
          <div>
            <IconButton
              onClick={() => handleView(id, 'modifyStatus')}
              disabled={isDisabled}
              style={isDisabled ? disabledButtonStyles : enabledButtonStyles}
            >
              <ManageHistory />
            </IconButton>
          </div>
        </LightTooltip>

        <LightTooltip title="Asignar a Trabajador" TransitionComponent={Fade}>
          <div>
            <IconButton
              onClick={() => handleView(id, 'delegate')}
              disabled={isDisabled}
              style={isDisabled ? disabledButtonStyles : enabledButtonStyles}
            >
              <HowToReg />
            </IconButton>
          </div>
        </LightTooltip>

        <LightTooltip title="Historial" TransitionComponent={Fade}>
          <div>
            <IconButton onClick={() => handleView(id, 'record')} style={enabledButtonStyles}>
              <WorkHistory />
            </IconButton>
          </div>
        </LightTooltip>
      </>
    )
  }

  const handleExport = () => {
    const data = filteredReports.map(report => {
      const time1 =
        report.status !== 'Close'
          ? moment.duration(Date.now() - new Date(report.createdAt).getTime())
          : null

      const time2 =
        report.status === 'Close'
          ? moment.duration(
              new Date(report.updatedAt).getTime() - new Date(report.createdAt).getTime()
            )
          : null

      const formatDuration = (duration: moment.Duration | null) => {
        if (!duration) return ''
        const days = duration.days()
        const hours = duration.hours()
        const minutes = duration.minutes()

        return `${days ? `${days} D` : ''} ${hours ? `${hours} H` : ''} ${
          minutes ? `${minutes} M` : ''
        }`
      }

      return {
        ID: report.shortID,
        'TICKET TIPO': report.priority,
        'RESIDENTE REPORTÓ/SOLICITÓ': report.priority !== 'Express' ? report.description : '',
        'STAFF RESPONDE': report.response ? report.response : '',
        STAFF: report.resolvedBy,
        ESTADO: options.find(option => option.value === report.status)?.label || 'Pendiente',
        'FECHA CREACIÓN': moment(report.createdAt).format('DD/MM/YYYY'),
        HORA: moment(report.createdAt).format('h:mm a'),
        CATEGORIA: report.category,
        TIME: formatDuration(time1),
        'FECHA CIERRE':
          report.status === 'Close' ? moment(report.updatedAt).format('DD/MM/YYYY') : '',
        ' HORA ': report.status === 'Close' ? moment(report.updatedAt).format('h:mm a') : '',
        ' TIME ': report.priority !== 'Express' ? formatDuration(time2) : 'Express'
      }
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)

    // Estilo de encabezado
    const headerCellStyle = {
      font: { bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: '800080' } }, // Color morado
      alignment: { horizontal: 'center', vertical: 'center' }
    }

    // Ancho de columnas (C y D con 200px, el resto 100px)
    if (data.length === 0) return
    const defaultWidth = 100
    worksheet['!cols'] = Object.keys(data[0]).map((_, index) => ({
      wpx: index === 2 || index === 3 ? 200 : defaultWidth // Columnas C y D (índices 2 y 3) con 200px, el resto 100px
    }))

    // Aplicar estilos a los encabezados
    const range = XLSX.utils.decode_range(worksheet['!ref']!)
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + '1'
      if (worksheet[address]) {
        worksheet[address].s = headerCellStyle
      }
    }

    // Estilo para las celdas de datos
    const cellStyle = {
      alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } }
      }
    }

    // Aplicar estilos a las celdas
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ r: R, c: C })
        if (worksheet[address]) {
          worksheet[address].s = {
            ...cellStyle,
            fill: { fgColor: { rgb: R % 2 === 0 ? 'E6E6FA' : 'FFFFFF' } } // Intercalar morado claro y blanco
          }
        }
      }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reportes')
    XLSX.writeFile(
      workbook,
      `${user?.name}-${moment(Date.now()).format('DD_MM_YYYY-h_mm')}.xlsx`
    )
  }

  return (
    <div className="DashboardContainer">
      <div className="containerTitle">
        <h4>Dashboard {user?.role !== 'corporate' ? `` : GeneralData.clientName}</h4>
      </div>
      {GeneralData && (
        <div>
          {selected && (
            <>
              {selected.action === 'modifyStatus' && (
                <Modal
                  isOpen={isModalOpen}
                  styleContainer={{
                    width: '900px',
                    height: '490px',
                    padding: '0px'
                  }}
                  onClose={closeModal}
                >
                  <ModifyStateModal reportId={selected.id} onClose={closeModal} />
                </Modal>
              )}
              {selected.action === 'delegate' && (
                <Modal
                  isOpen={isModalOpen}
                  styleContainer={{
                    width: '830px',
                    height: '450px',
                    padding: '0px'
                  }}
                  onClose={closeModal}
                >
                  <AssignWorkerModal
                    reportId={selected.id}
                    Staffs={GeneralData.Staffs}
                    onClose={closeModal}
                  />
                </Modal>
              )}
              {selected.action === 'record' && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                  <ReportModal reportId={selected.id} />
                </Modal>
              )}
            </>
          )}

          <div className="infoGeneralContainer">
            <div style={{ position: 'absolute', left: '-40px' }}>
              <IconButton onClick={() => setSlider(!slider)}>
                {slider ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>

            <LightTooltip title="Eficiencia Global" followCursor TransitionComponent={Fade}>
              <li className="generalInf tooltip">
                <FontAwesomeIcon icon={faBuilding} className="icon" />
                <span>{`${GeneralData?.ALL_RSE}`}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Eficiencia en reportes "
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faTicket} className="icon" />
                <span>{GeneralData.ALL_RS}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Eficiencia en emergencias"
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
                <span>{GeneralData.ALL_E}%</span>
              </li>
            </LightTooltip>

            <li className="generalInf">
              <LightTooltip
                title={`${GeneralData.clientName} toma ticket en:`}
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf">
                  <FontAwesomeIcon icon={faHandPointUp} className="icon" />
                  <span>{GeneralData.textTakeAverage}</span>
                </span>
              </LightTooltip>

              <LightTooltip
                title="Relación mes anterior en toma de ticket"
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf">
                  <FontAwesomeIcon
                    icon={
                      GeneralData.percentageChangeTakeAverage > 0
                        ? faCircleArrowUp
                        : faCircleArrowDown
                    }
                    className={`icon ${
                      GeneralData.percentageChangeTakeAverage > 0
                        ? 'icon-negative'
                        : 'icon-positive'
                    }`}
                  />
                  <span>
                    {GeneralData.percentageChangeTakeAverage > 0
                      ? GeneralData.percentageChangeTakeAverage
                      : GeneralData.percentageChangeTakeAverage * -1}
                    %
                  </span>
                </span>
              </LightTooltip>
            </li>

            <li className="generalInf">
              <LightTooltip
                title={`${GeneralData.clientName} cierra ticket en:`}
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf">
                  <ExitToAppOutlined className="icon iconSvg" />
                  <span>{GeneralData.textClosingAverage}</span>
                </span>
              </LightTooltip>

              <LightTooltip
                title="Relación mes anterior en cierre de ticket"
                followCursor
                TransitionComponent={Fade}
              >
                <span className="generalInf">
                  <FontAwesomeIcon
                    icon={
                      GeneralData.percentageChangeClosingAverage > 0
                        ? faCircleArrowUp
                        : faCircleArrowDown
                    }
                    className={`icon ${
                      GeneralData.percentageChangeClosingAverage > 0
                        ? 'icon-negative'
                        : 'icon-positive'
                    }`}
                  />
                  <span>
                    {GeneralData.percentageChangeClosingAverage > 0
                      ? GeneralData.percentageChangeClosingAverage
                      : GeneralData.percentageChangeClosingAverage * -1}
                    %
                  </span>
                </span>
              </LightTooltip>
            </li>
          </div>

          <ClientBanner
            items={GeneralData.Staffs}
            renderItem={(client, id) => (
              <>
                <LightTooltip
                  title={`${client.name || ''}`}
                  followCursor
                  TransitionComponent={Fade}
                >
                  <img
                    className="client-photo"
                    src={
                      client.photo !== 'null' && client.photo !== 'undefined'
                        ? client.photo
                        : '/images/icons/user.png'
                    }
                    alt={client.name || 'User'}
                  />
                </LightTooltip>

                <LightTooltip title="Reportes tomados" followCursor TransitionComponent={Fade}>
                  <p className="client-info">{client.S_TT || 0}/</p>
                </LightTooltip>

                <LightTooltip
                  title="Reportes resueltos"
                  followCursor
                  TransitionComponent={Fade}
                >
                  <p className="client-info">{client.S_TR || 0}/</p>
                </LightTooltip>

                <LightTooltip title="Eficiencia" followCursor TransitionComponent={Fade}>
                  <p className="client-info">{client.S_OK || 0}%</p>
                </LightTooltip>
              </>
            )}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: slider ? `2.5rem` : '0rem',
              maxHeight: slider ? '400px' : '0px',
              overflow: 'hidden',
              transition: 'max-height 0.5s linear 0s, margin-top 0.5s linear 0s'
            }}
          >
            <div style={{ width: '55%' }}>
              <ReactECharts
                option={barChartOptions(GeneralData)}
                style={{ height: '400px', width: '100%' }}
              />
            </div>
            <div style={{ width: '45%' }}>
              <ReactECharts
                option={pieChartOptions(GeneralData)}
                style={{ height: '400px', width: '100%' }}
              />
            </div>
          </div>

          <div className="dashboard-search-section">
            <h5 className="dashboard-barStatus">ESTATUS</h5>

            <MultiSelect
              placeholder="Todos"
              options={options}
              value={search.status}
              Name="status"
              onChange={event => handleChange(event)}
            />

            <Select
              placeholder={'Prioridad'}
              options={[
                { value: 'Emergency', label: 'Emergencia' },
                { value: 'Report', label: 'Reporte' },
                { value: 'Express', label: 'Express' }
              ]}
              value={`Prioridad`}
              Name="priority"
              onChange={value => handleChange(value)}
            />
            <Select
              placeholder={'Empleados'}
              options={GeneralData.Staffs.map(staff => ({
                value: staff.id,
                label: staff.name
              }))}
              value={``}
              Name="staff"
              onChange={value => handleChange(value)}
            />
            <Select
              placeholder={'Orden'}
              options={[
                { value: 'asc', label: 'Recientes' },
                { value: 'desc', label: 'Antiguos' }
              ]}
              value={`Orden`}
              Name="order"
              onChange={value => handleChange(value)}
            />

            <DateRangePicker
              label1={'Desde'}
              label2={'Hasta'}
              onChange={value => {
                setSearch(prevSearch => ({
                  ...prevSearch,
                  dateSelect: value // Asegúrate de que sea un string
                }))
              }}
            ></DateRangePicker>

            <LightTooltip title="Descargar Reporte" TransitionComponent={Fade}>
              <IconButton
                onClick={handleExport}
                style={{ border: 'none', padding: 0, margin: 0 }}
                disableRipple
                disableFocusRipple
              >
                <GridOn style={{ color: 'white', fontSize: 30, marginBottom: '4px' }} />
              </IconButton>
            </LightTooltip>

            <div className="dashboard-search-bar-container-dashboard">
              <div className="dashboard-search-icon">
                <Search style={{ color: 'white' }} />
              </div>
              <input
                name="description"
                onChange={e => handleChange(e)}
                type="text"
                className="dashboard-search-input"
                placeholder="Descripción..."
              />
            </div>
          </div>

          <div className="tickets-container">
            <div className="ticket-header">
              <div className="ticket-info-header">
                <span className="ticket-status">Estado</span>
                <span className="ticket-priority">Prioridad</span>
                <span className="ticket-description">Descripción</span>
                <span className="ticket-status">Foto</span>
                <span className="ticket-id">ID</span>
                <span className="ticket-time">Tiempo</span>
                <span className="ticket-staff">Encargado</span>
              </div>

              <div className="ticket-actions">Acciones</div>
            </div>

            {filteredReports.map((report, index) => (
              <div key={index} className="ticket">
                <div className="ticket-info">
                  <span style={{ position: 'absolute', left: '0px' }}>
                    {statusSvg[report.status]}
                  </span>

                  <span className="ticket-status">
                    {options.find(option => option.value === report.status)?.label ||
                      'Pendiente'}
                  </span>
                  <span className="ticket-priority">{priority[report.priority]}</span>

                  <LightTooltip
                    title={`${
                      report.description ? `Residente: "${report.description}"\n\n` : ''
                    }${report.response ? `Trabajador: "${report.response}".` : ``}`}
                    followCursor
                    TransitionComponent={Fade}
                  >
                    <span className="ticket-description">
                      {report.description
                        ? report.description.length > 25
                          ? report.description.slice(0, 25) + '...'
                          : report.description
                        : report.response
                        ? report.response.length > 25
                          ? report.response.slice(0, 25) + '...'
                          : report.response
                        : ''}
                    </span>
                  </LightTooltip>
                  <PhotoTooltip
                    photos={[report.lastPhotoKey, report.initialReport, report.closedReport]}
                    altText={report.shortID}
                    altPhoto="images/logo_inicio.png"
                    className="custom-tooltip"
                  />
                  <span className="ticket-id">{report.shortID}</span>
                  <span className="ticket-time">{getTimeDifference(report.createdAt)}</span>
                  <span className="ticket-staff">{report.resolvedBy}</span>
                </div>
                {Actions && (
                  <div className="ticket-actions">
                    {<Actions id={report.id} state={report.status} />}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
