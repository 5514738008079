import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import ReactDOM from 'react-dom'
import { QRCodeCanvas } from 'qrcode.react'
import { CheckPointsLocation } from '../../types/CheckPoints_Location'
import React from 'react'

const renderQRCodeToCanvas = (qrValue: string, size: number): Promise<HTMLCanvasElement> => {
  return new Promise(resolve => {
    // Crear un contenedor temporal
    const container = document.createElement('div')

    // Componente QRCodeCanvas
    const qrCodeElement = <QRCodeCanvas value={qrValue} size={size} level={'M'} />

    ReactDOM.render(qrCodeElement, container, () => {
      const canvas = container.querySelector('canvas') as HTMLCanvasElement
      resolve(canvas)
      // Limpiar el contenedor temporal después de renderizado
      ReactDOM.unmountComponentAtNode(container)
    })
  })
}

const generateAndDownloadZip = async (
  items: CheckPointsLocation[],
  clientName: string,
  Title: string
) => {
  const zip = new JSZip()

  const promises = items.map(async item => {
    try {
      // Renderizado el QR code en un canvas temporal
      const qrCanvas = await renderQRCodeToCanvas(item.qrCode, 300)

      const img = new Image()
      img.src = qrCanvas.toDataURL('image/png')

      await new Promise<void>(resolve => {
        img.onload = () => {
          const imageData = drawBackground(img, item, clientName, item.type)
          const fileName = `qr_${item.name}_${clientName}_${item.address}.jpeg`
          zip.file(fileName, imageData.split(',')[1], { base64: true })
          resolve()
        }
      })
    } catch (error) {
      console.error('Error al generar la imagen QR:', error)
    }
  })

  await Promise.all(promises)
  const zipBlob = await zip.generateAsync({ type: 'blob' })
  saveAs(zipBlob, `${items.length}_Img_${clientName}_${Title.toUpperCase()}.zip`)
}

const drawBackground = (
  elem: HTMLImageElement,
  item: CheckPointsLocation,
  clientName: string,
  type: 'Limpieza' | 'Mantenimiento' | 'Seguridad'
): string => {
  const c = document.createElement('canvas')
  const ctx = c.getContext('2d') as CanvasRenderingContext2D
  const qrSize = 708 // 6 cm en píxeles a 300 DPI

  const qrMargin = 10
  const textMargin = 5

  c.width = qrSize
  c.height = qrSize

  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, c.width, c.height)

  ctx.font = 'bold 2.8rem Arial'
  ctx.fillStyle =
    type === 'Mantenimiento' ? '#008000' : type === 'Seguridad' ? '#002d80' : '#000'
  ctx.textAlign = 'center'
  const textYStart = qrMargin + textMargin + 25
  ctx.fillText(clientName || '', c.width / 2, textYStart)

  ctx.font = 'bold 2rem Arial'
  const typeY = textYStart + 1.5 * 24
  ctx.fillText(`CHECKPOINT ${item?.type.toUpperCase() || ''}`, c.width / 2, typeY)

  const qrSizeAdjusted = qrSize * 0.83
  const qrX = (c.width - qrSizeAdjusted) / 2
  const qrY = typeY + 8
  ctx.drawImage(elem, 0, 0, elem.width, elem.height, qrX, qrY, qrSizeAdjusted, qrSizeAdjusted)

  ctx.font = 'bold 2.2rem Arial'
  const bottomText = `${item?.name || ''} - ${item?.address || ''}`
  const bottomY = c.height - textMargin
  ctx.fillText(bottomText, c.width / 2, bottomY)

  return c.toDataURL('image/jpeg')
}

export const downloadAllImages = async (
  items: CheckPointsLocation[],
  clientName: string,
  Title: string
) => {
  await generateAndDownloadZip(items, clientName, Title)
}
