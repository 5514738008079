import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

// Función para convertir el tiempo restante en formato MM:SS
const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

export const TimeToken = () => {
  const { user, logout, refreshToken, SocketBackEnd } = useAuth()
  const [showBanner, setShowBanner] = useState(false)
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const [leftPosition, setLeftPosition] = useState(0)
  const bannerRef = useRef<HTMLDivElement>(null)

  // Actualiza la posición horizontal del banner
  const updateBannerPosition = () => {
    const container = document.getElementById('main')
    if (!container || !bannerRef.current) return

    const containerRect = container.getBoundingClientRect()
    const bannerWidth = bannerRef.current.offsetWidth

    const newLeftPosition = containerRect.left + (containerRect.width - bannerWidth) / 2
    setLeftPosition(newLeftPosition + 10)
  }

  // Efecto para calcular la posición inicial del banner y observar cambios en tamaño
  useEffect(() => {
    updateBannerPosition()

    const container = document.getElementById('main')
    if (!container) return

    const resizeObserver = new ResizeObserver(updateBannerPosition)
    resizeObserver.observe(container)

    return () => resizeObserver.disconnect()
  }, [showBanner])

  // Efecto para manejar el tiempo restante basado en el `timestamp` de expiración
  useEffect(() => {
    if (!user || !user.tokenEXP) return

    const currentTime = Math.floor(Date.now() / 1000) // Tiempo actual en segundos
    const expirationTime = user.tokenEXP // Timestamp de expiración en segundos
    const initialTimeLeft = expirationTime - currentTime

    setTimeLeft(initialTimeLeft)

    // Si falta más de 5 minutos, configuramos un timeout para empezar la cuenta regresiva
    if (initialTimeLeft > 300) {
      const timeout = setTimeout(() => {
        setTimeLeft(300) // Empieza la cuenta regresiva a los 300 segundos restantes
        setShowBanner(true)
      }, (initialTimeLeft - 300) * 1000) // Esperamos hasta que falten 5 minutos

      return () => clearTimeout(timeout)
    } else {
      setShowBanner(true)
    }
  }, [user])

  // Efecto para manejar la cuenta regresiva
  useEffect(() => {
    if (timeLeft <= 0) return

    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(interval)
          logout()
          setShowBanner(false)
          console.log('El token ha expirado.')
          return 0
        }

        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [timeLeft, logout])

  // Efecto para escuchar eventos de actualización del token vía WebSockets
  useEffect(() => {
    if (!SocketBackEnd || !user) return

    const handleTokenUpdate = (newExpirationTime: number) => {
      console.log('Token actualizado vía WebSocket:', newExpirationTime)

      const currentTime = Math.floor(Date.now() / 1000)
      const newTimeLeft = newExpirationTime - currentTime

      setTimeLeft(newTimeLeft)
      setShowBanner(false) // Oculta el banner si ya no es necesario
    }

    // Escuchar el evento del servidor
    const eventName = `updateTokent-${user.clientId}`
    SocketBackEnd.on(eventName, handleTokenUpdate)

    return () => {
      SocketBackEnd.off(eventName, handleTokenUpdate)
    }
  }, [SocketBackEnd, user])

  return (
    <>
      {showBanner && (
        <div
          className="expirationWarning borderAnimateIpass"
          ref={bannerRef}
          style={{ left: `${leftPosition}px` }}
        >
          <div className="inner">
            <div className="textZone">
              <h4>Tu sesión se cerrará en:</h4>
              <p>Al concluir el tiempo, deberás iniciar sesión nuevamente.</p>
            </div>
            <div className="time">{formatTime(timeLeft)}</div>
            <div className="resumeContainer" onClick={refreshToken}>
              <BorderIpass margin={2}>
                <div
                  style={{
                    width: '100px',
                    height: '25px',
                    textAlign: 'center',
                    margin: 'auto',
                    alignContent: 'center'
                  }}
                >
                  RENOVAR
                </div>
              </BorderIpass>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
