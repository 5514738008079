import React, { useEffect, useState } from 'react'
import './styles.scss'

import { detailReport } from '../../types/dashboard'
import { useLoading } from '../../Provider/LoadingProvider'
import { getLogsTicket } from '../../services/dashboard'
import PhotoTooltip from '../../components/tooltips/img'

export interface ReportModalProps {
  reportId: string
}

const ReportModal: React.FC<ReportModalProps> = ({ reportId }) => {
  const [report, setReport] = useState<detailReport | null>(null)
  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    getTicketData(reportId)
  }, [reportId])

  const getTicketData = async (_id: string) => {
    try {
      startLoading()
      const data = await getLogsTicket(reportId)
      setReport(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const options = [
    { value: 'Open', label: 'Abierto' },
    { value: 'Close', label: 'Cerrado' },
    { value: 'Hold', label: 'Espera' },
    { value: 'Evaluating', label: 'Evaluando' },
    { value: 'Spam', label: 'Irrelevante' }
  ]

  const optionsUpdate = [
    { value: 'Open', label: 'Abre' },
    { value: 'Close', label: 'Cierra' },
    { value: 'Hold', label: 'Trabajando' },
    { value: 'Evaluating', label: 'Enviado a evaluar' },
    { value: 'Spam', label: 'Marca como irrelevante' }
  ]

  const priority: {
    [key: string]: string
  } = {
    Emergency: 'Emergencia',
    Report: 'reporte',
    Express: 'Ticket Express',
    CheckPoint: 'CheckPoint'
  }
  if (!report) return <div className="modal">.</div>

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>
            {report.priority === 'Express'
              ? ' Historial Tickets Express'
              : 'Seguimiento del Reporte'}
          </h2>
          <p>
            <span>Código:</span> {report.shortID}
          </p>
        </div>
        <div className="log-entry">
          <div className="textRight">
            <div>
              <span>Fecha: </span> {report.createdAt}
            </div>

            <div>
              <span>Creado por: </span> {report.createdName}
            </div>
          </div>
          <div className="separate">
            {
              <div className={`separateTicketPhotoLast`}>
                <PhotoTooltip
                  photos={[report.PhotoCreatedAt]}
                  altText={report.shortID}
                  altPhoto="images/linear.png"
                  className="ticket-photo"
                />
              </div>
            }
          </div>
          <div className="dataLogs">
            <div>
              <span>Prioridad: </span>
              {priority[report.priority]}
            </div>
            <div>
              <span>Estado actual: </span>
              {options.find(option => option.value === report.status)?.label || 'Pendiente'}
            </div>
            {report.resolvedById && (
              <div>
                <span> {report.priority === 'Express' ? 'Notificado' : 'Tomado Por:'} </span>{' '}
                {report.resolvedById}
              </div>
            )}
          </div>
        </div>

        <div className="center">
          <span>Descripción:</span> <p>{report.description}</p>
        </div>

        {report.logs && (
          <div className="logs-timeline">
            {report.logs.map((log, index) => (
              <div key={index} className="log-entry">
                <div className="textRight">
                  <div>{log.timestamp}</div>
                  <span>{log.idStaff}</span>
                </div>
                <div className="separate">
                  {
                    <div
                      className={`${
                        index === -1 || index + 1 === report.logs?.length
                          ? 'separateTicketPhotoLast'
                          : 'separateTicketPhoto'
                      }`}
                    >
                      <PhotoTooltip
                        photos={[log.photo]}
                        altText={report.shortID}
                        altPhoto="images/ok.jpg"
                        className="ticket-photo"
                      />
                    </div>
                  }
                </div>
                <div className="dataLogs">
                  <div>
                    {optionsUpdate.find(option => option.value === log.newState)?.label ||
                      'Pendiente'}
                  </div>
                  {log.description && <div>{log.description}</div>}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ReportModal
