import React, { FC, useState } from 'react'
import Switch from '../../../Form/Switch'
import PopUpConfirm from '../PopupConfirm'

const EnableComponent: FC<{
  onUpdate: () => void
  enable: boolean
  title: string
  description?: string
  acceptText?: string
  rejectText?: string
  activeColor?: string
  ColorDiscative?: string
}> = ({
  onUpdate,
  enable,
  description,
  title,
  activeColor,
  ColorDiscative,
  acceptText = 'Aceptar',
  rejectText = 'Cancelar'
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    onUpdate()
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  return (
    <div>
      <Switch
        value={enable}
        onChange={(_e: boolean) => setIsPopUpOpen(true)}
        activeColor={activeColor}
        ColorDiscative={ColorDiscative}
      />
      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title={title}
        description={description}
        acceptText={acceptText}
        rejectText={rejectText}
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </div>
  )
}

export default EnableComponent
