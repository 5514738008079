import React from 'react'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

export interface ReportModalProps {
  onSend: (x: any) => void
  onClose: () => void
}

export const Warning: React.FC<ReportModalProps> = ({ onClose, onSend }) => {
  return (
    <div className="miniWarnig">
      <>
        <h3>¡¡CUIDADO!!</h3>
        <p>{`Debes observar tu próxima fecha de corte, pues este cargo podría ser aplicado en el mes que está por vencer.`}</p>
        <p>{`Recomendable lo apliques cuando la fecha de corte del mes vencido haya pasado`}</p>
        <div className="Bottom">
          <BorderIpass margin={3} onClick={onClose}>
            CANCELAR
          </BorderIpass>

          <BorderIpass margin={3} onClick={onSend}>
            CONTINUAR
          </BorderIpass>
        </div>
      </>
    </div>
  )
}
